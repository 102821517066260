import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GamesService } from '../../../services/games.service';
import { GeneralService } from '../../../services/general.service';
import { dic } from '../../../dictionary';
import { GameInfoComponent } from '../../widgets/game-info/game-info.component';
import { NgClass, NgIf, NgFor, NgStyle } from '@angular/common';
import { FormsModule } from '@angular/forms';


@Component({
    selector: 'app-all-games',
    templateUrl: './all-games.component.html',
    styleUrls: ['./all-games.component.scss'],
    standalone: true,
    imports: [FormsModule, NgClass, NgIf, NgFor, NgStyle, GameInfoComponent]
})
export class AllGamesComponent implements OnInit {

	allGamesData: any;
	dic = dic;
  searchGameText: any;
  gamesGenre: string = '';
  public isDropdownOpen: boolean = false;

	constructor(public gamesService: GamesService, private generalService: GeneralService,
              private activatedRoute: ActivatedRoute) {
    const data = this.activatedRoute.snapshot.data;
    let currentState = 'games';
    if (data && data['genre']) {
      this.gamesGenre = data['genre'];
      currentState += `/${this.gamesGenre.toLowerCase()}`
    }
    this.generalService.updateState(currentState, null);
  }

	ngOnInit() {
    // this.allGamesData = this.gamesService.getStaticGames();

    this.gamesService.getAllGames((err: any, games: any) => {
			this.allGamesData = games;
      this.displaySearchResults('');
      this.sortGames(this.gamesService.sortGamesBy);
		});
	}

  sortGames(type: string) {
    this.gamesService.sortGamesBy = type;
    switch (type) {
      case 'played':
        this.allGamesData.sort((a: any, b: any) => b.counter - a.counter);
        break;

      case 'favourite':
        this.allGamesData.sort((a: any, b: any) => b.favourite - a.favourite);
        break;

      case 'rated':
        this.allGamesData.sort((a: any, b: any) => (b.rating.sum / (b.rating.counter || 1)) - (a.rating.sum / (a.rating.counter || 1)));
        break;

      case 'year':
        this.allGamesData.sort((a: any, b: any) => b.year - a.year);
        break;

      case 'alphabetical':
        this.allGamesData.sort((a: any, b: any) => b.name < a.name ? 1 : -1);
        break;
    }

    this.isDropdownOpen = false;
	}

  displaySearchResults(searchGameText: string) {
    if (!searchGameText) {
      this.allGamesData.forEach((gameObj: any) => {
        if (this.gamesGenre) {
            if (gameObj.genre !== this.gamesGenre) {
              gameObj.hide = true;
              return;
            }
        }
        gameObj.hide = false;
      });
      return;
    }

    this.allGamesData.forEach((gameObj: any) => {
      if (this.gamesGenre) {
        if (gameObj.genre !== this.gamesGenre) {
          gameObj.hide = true;
          return;
        }
      }
      gameObj.hide = !gameObj.name.toLowerCase().includes(searchGameText.toLowerCase());
    });
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }
}
