import {Component, Input, OnInit} from '@angular/core';
import { GeneralService } from '../../../services/general.service';
import { GameInfoComponent } from '../game-info/game-info.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, GameInfoComponent]
})
export class CarouselComponent implements OnInit {

  currentGameIndex = 0;
  isBtnGameClicked = false;

  @Input() games: any;
  @Input() hideMeta: boolean = false;
  @Input() gamesShow: number = 0;
  @Input() showMaxGames: boolean = false;
  constructor(public generalService: GeneralService) { }

  ngOnInit(): void {
    if (this.generalService.isBrowser()) {
      const maxGamesInPage = Math.floor(window.innerWidth / 180) || 1;
      if (this.showMaxGames) {
        this.gamesShow = maxGamesInPage;
      }
      else {
        this.gamesShow = Math.min(this.gamesShow, maxGamesInPage);
      }
    }
  }

  changeGames(isNext: boolean) {
    if (isNext) {
      this.currentGameIndex += this.gamesShow;
      if (this.currentGameIndex >= this.games.length) {
        this.currentGameIndex = 0;
      }
    }
    else {
      this.currentGameIndex -= this.gamesShow;
      if (this.currentGameIndex <= 0) {
        this.currentGameIndex = this.games.length - 1;
      }
    }

    this.isBtnGameClicked = true;
    setTimeout(() => {
      this.isBtnGameClicked = false;
    }, 1000);
  }

}
