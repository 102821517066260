import { Component, OnInit } from '@angular/core';
import { dic } from '../../../dictionary';
import { RouterLink } from '@angular/router';
import { IconsComponent } from '../icons/icons.component';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    standalone: true,
    imports: [IconsComponent, RouterLink]
})
export class FooterComponent implements OnInit {
	dic = dic;
	constructor() { }

	ngOnInit(): void {}
}
