import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../../services/general.service';
import { dic } from '../../../dictionary';
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { IconsComponent } from '../../widgets/icons/icons.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-blogs',
    templateUrl: './blog.component.html',
    styleUrls: ['./blog.component.scss'],
    standalone: true,
    imports: [NgIf, RouterLink, IconsComponent]
})
export class BlogComponent implements OnInit {
  dic = dic;
  selectedBlog: string | null = "any";
  constructor(private generalService: GeneralService, private activatedRoute: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    this.generalService.updateState(dic.STATES.blogs, null);

    this.selectedBlog = this.activatedRoute.snapshot.paramMap.get('name');
    if (!this.selectedBlog) {
      this.selectedBlog = 'top10';
    }

    // @ts-ignore
    const options = META_INFO[this.selectedBlog];
    this.generalService.updateMetaData(dic.STATES.blogs, options);
  }

  selectBlog(selectedBlog: string) {
    // 2 routes to enforce route to be called
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
      this.router.navigate([`/${dic.STATES.blogs}/${selectedBlog}`]));
  }
}

const META_INFO = {
  top10: {
    name: 'Top 10 DOS games',
    meta: 'Experience the best of online DOS games. Explore the top 10 classics, relish nostalgia, and immerse in unforgettable gameplay. Play now!'
  },
  playOnline: {
    name: 'Play DOS games',
    meta: 'Relive the retro gaming experience. Play classic DOS games online and rediscover pixelated nostalgia. Join us for endless gaming memories!'
  },
  fun: {
    name: 'Fun DOS games',
    meta: 'Unveil the timeless fun of playing DOS games online. Immerse in the irresistible charm of classics. Rediscover nostalgia and endless enjoyment now!'
  },
  emulator: {
    name: 'DOS games emulator',
    meta: 'Experience the thrill of playing DOS games in an emulator. Relive retro classics, dive into nostalgia, and enjoy gaming like never before.'
  },
  policeQuest: {
    name: 'Police Quest',
    meta: 'Relive the DOS era and rediscover the thrill of Police Quest games. Play online and immerse yourself in captivating police adventures. Enjoy now!'
  }
}
