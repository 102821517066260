import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class RouteService {

  private baseUrl = 'https://backendgames.herokuapp.com';

  constructor(private http: HttpClient) { }


  getAllGames(): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}/games`);
  }

  updateGame(gameId: any, data: any): Observable<any[]> {
    return this.http.put<any[]>(`${this.baseUrl}/games/${gameId}`, data);
  }

  contactUs(data: any): Observable<any[]> {
    return this.http.post<any[]>(`${this.baseUrl}/general/contact`, data);
  }
}
