import {Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GamesService } from '../../../services/games.service';
import { GeneralService } from '../../../services/general.service';
import { DomSanitizer } from '@angular/platform-browser';

import { dic } from '../../../dictionary';
import { CarouselComponent } from '../../widgets/carousel/carousel.component';
import { RunGameComponent } from '../../widgets/run-game/run-game.component';
import { StarRatingModule } from 'angular-star-rating';
import { NgIf } from '@angular/common';
import { IconsComponent } from '../../widgets/icons/icons.component';
// declare let FB: any;

@Component({
    selector: 'app-game',
    templateUrl: './game.component.html',
    styleUrls: ['./game.component.scss'],
    standalone: true,
    imports: [IconsComponent, NgIf, StarRatingModule, RunGameComponent, CarouselComponent]
})
export class GameComponent implements OnInit {
	dic = dic;

	selectedGame: any;
	gameInfo: any = {};
  gameUsageInfo: any = {};
	gamesByGenre: any;
  currentGameURL = `${dic.CONSTANTS.appUrL}game/`;
  gameRated: boolean = false;
  autoStartGame: boolean = false;
  whatsappShareLink: string = '';
  tiktokShareUrl: string = '';
  instagramShareLink: string = 'https://www.instagram.com/dosgamesworld/';
  tweeterShareLink: string = '';
  youtubeLink: any;

	constructor(private gamesService: GamesService,
		private activatedRoute: ActivatedRoute, private generalService: GeneralService,
              private sanitizer: DomSanitizer) {

/*    const currentNavigation = router.getCurrentNavigation();
    if (currentNavigation && currentNavigation.extras && currentNavigation.extras.state && currentNavigation.extras.state) {
     this.autoStartGame = true;
      console.log(currentNavigation.extras.state);
    }*/
  }

	ngOnInit(): void {
		const gameName = this.activatedRoute.snapshot.paramMap.get('name');
		if (!gameName) {
			console.error(`failed to find game ${gameName}`);
			return;
		}

    this.gameInfo = (dic.GAMES_INFO)[gameName as keyof typeof dic.GAMES_INFO];
		if (!this.gameInfo) {
			console.error(`failed to find game ${gameName}`);
			return;
		}

    this.currentGameURL += this.gameInfo.fileName;

    this.generalService.updateState(dic.STATES.game, this.gameInfo);

		this.getGameUsageInfo(gameName);

    this.gamesByGenre = this.gamesService.getStaticGames();
    // shuffle array
    this.gamesByGenre.sort(() => Math.random() - 0.5);

    // this.facebookLink(this.gameInfo);
    this.whatsappLink(this.gameInfo);
    this.tweeterLink(this.gameInfo);
    this.tiktokLink(this.gameInfo);
    this.setYoutubeLink(this.gameInfo);

    this.checkAutoGameRun();
  }

  setYoutubeLink(gameInfo: any) {
    if (!gameInfo.youtube) {
      return;
    }

    this.youtubeLink = this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${gameInfo.youtube}`);
  }

  whatsappLink(gameInfo: any) {
    const message = `Play ${gameInfo.name} online here - `;
    const encodedMessage = encodeURIComponent(message);
    const encodedLink = encodeURIComponent(this.currentGameURL);
    this.whatsappShareLink = `https://api.whatsapp.com/send?text=${encodedMessage}%20${encodedLink}`;
  }

  tweeterLink(gameInfo: any) {
    const message = `Play ${gameInfo.name} online here - `;
    const encodedMessage = encodeURIComponent(message);
    const encodedLink = encodeURIComponent(this.currentGameURL);
    this.tweeterShareLink = `https://twitter.com/share?text=${encodedMessage}&url=${encodedLink}`;
  }

  tiktokLink(gameInfo: any) {
    this.tiktokShareUrl = `https://www.tiktok.com/`;
  }

/*  facebookLink(gameInfo: any) {
    try {
      if (this.generalService.isBrowser()) {
        FB.XFBML.parse();
      }
    }
    catch (ex) {
    }
  }*/

  checkAutoGameRun() {
    if (this.autoStartGame) {
      this.playGame(this.gameInfo);
    }
  }

  getGameUsageInfo(gameName: string) {
    this.gamesService.getGameInfo(gameName, (err: any, gameUsageInfo: any) => {
      if (gameUsageInfo) {
        this.gameUsageInfo = gameUsageInfo;
        this.gameInfo.counter = gameUsageInfo.counter;
        this.gameInfo.favourite = gameUsageInfo.favourite;
        this.gameInfo.userFavourite = gameUsageInfo.userFavourite;
        this.gameInfo.rating = gameUsageInfo.rating;
        this.gameInfo._id = gameUsageInfo._id;

        this.gamesService.updateGameViewed(this.gameInfo);
      }
    });
  }

	playGame(selectedGame: any) {
    this.selectedGame = selectedGame;
	}

  updateGameRate($event: any) {
    if (this.gameRated) {
      return;
    }
    this.gameRated = true;
    this.gamesService.updateGameRate(this.gameInfo, $event.rating);
  }

	unselectGame() {
		this.selectedGame = null;
	}

	downloadGame(gameInfo: any) {
		const downloadUrl = `${dic.CONSTANTS.appUrL}assets/games/${gameInfo.fileName}.jsdos`;
		window.open(downloadUrl, '_blank');
	}
}
