<div class="bodyContainer">
  <div id="allGamesContainer">

    <h1 class="dosTitle">
      Play {{gamesGenre ? gamesGenre + ' ' : ''}}DOS games online
    </h1>

    <p class="text dosText">
      Check out our wide collection of {{gamesGenre ? gamesGenre + ' ' : ''}}DOS games you can play online or download for free.<br>
      We continuously work to expand our collection of {{gamesGenre ? gamesGenre + ' ' : ''}}games for you to enjoy.
    </p>

    <div class="gameFilter">
      <div class="gameSearch">
        <label class="searchLabel" for="searchInnerGame"><b>Search:\></b></label>
        <input class="gamesSearchInput" placeholder="DOS game" id="searchInnerGame" [(ngModel)]="searchGameText" type="search" autocomplete="off"
               maxlength="20" (input)="displaySearchResults(searchGameText)">
      </div>

      <span>|</span>
      <span>Sort DOS games by:</span>

      <div class="dos-dropdown" [ngClass]="{'show': isDropdownOpen}">
        <button class="dos-dropdown-selected" (click)="toggleDropdown()">
          {{this.gamesService.sortGamesBy}}
        </button>
        <div class="dos-dropdown-content" *ngIf="isDropdownOpen">
          <div class="dos-dropdown-item" (click)="sortGames('played')">Played</div>
          <div class="dos-dropdown-item" (click)="sortGames('favourite')">Favourite</div>
          <div class="dos-dropdown-item" (click)="sortGames('rated')">Rated</div>
          <div class="dos-dropdown-item" (click)="sortGames('year')">Year</div>
          <div class="dos-dropdown-item" (click)="sortGames('alphabetical')">Alphabetical</div>
        </div>
        <div class="dos-dropdown-arrow"></div>
      </div>
    </div>

    <div class="viewGamesContainer">
      <div *ngFor="let gameInfo of allGamesData" [ngStyle]="{'display': gameInfo.hide ? 'none' : ''}">
        <app-game-info *ngIf="!gameInfo.hide" [gameInfo]="gameInfo"></app-game-info>
      </div>
    </div>

  </div>
</div>
