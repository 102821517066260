<div id="playContainer" class="js-dos" style="display: flex; flex-direction: column;">

  <div class="gamePlayHeader">
    <div style="display: flex; flex-direction: row;">
      <span><b>Game Rating: </b></span>
      <star-rating
        style="cursor: pointer;"
        [hoverEnabled]="true"
        [starType]="'svg'"
        [rating]="gameInfo.rating.avg"
        (starClickChange)="updateGameRate($event)">
      </star-rating>
      <span style="font-size: 17px;">(By {{gameInfo.rating?.counter}} players)</span>
    </div>

    <div *ngIf="gameInfo.keys" class="tooltip">
      <span class="tooltiptext" [innerHtml]="gameInfo.keys"></span>
      <app-icons [gameInfo]="gameInfo" [icon]="dic.ICONS.keyboard" ></app-icons>
    </div>

    <div *ngIf="gameInfo.cheats" class="tooltip">
      <span class="tooltiptext" [innerHtml]="gameInfo.cheats"></span>
      <app-icons [gameInfo]="gameInfo" [icon]="dic.ICONS.cheats" ></app-icons>
    </div>

    <div (click)="reportBug()" style="cursor: pointer;" title="Report a bug">
      <app-icons [gameInfo]="gameInfo" [icon]="dic.ICONS.bug" ></app-icons>
    </div>

    <div>
      <a href="{{tweeterShareLink}}" target="_blank">
        <img src="../../assets/social/tweet.webp" width="48" height="48" alt="tweeter">
      </a>
    </div>

    <div>
      <a href="{{whatsappShareLink}}" target="_blank">
        <img src="../../assets/social/whatsapp.webp" width="48" height="48" alt="WhatsApp">
      </a>
    </div>

    <div>
      <a href="{{instagramShareLink}}" target="_blank">
        <img src="../../assets/social/instagram.webp" width="48" height="48" alt="Instagram">
      </a>
    </div>

<!--    <div class="fb-share-button" [attr.data-href]="currentGameURL" data-layout="button"
         data-size="large" id="fbBtn">
    </div>-->

    <button (click)="closeGame()" class="exitGameBtn" style="width: 120px; padding-left: 5px;">
      <div class="flex-row" style="gap: 10px;">
        <span style="display: flex; align-items: center;">Exit Game</span>
        <app-icons [gameInfo]="gameInfo" [icon]="dic.ICONS.exit" ></app-icons>
      </div>
    </button>
  </div>


  <div *ngIf="downloadProgress !== 100" class="loading-animation">
    <div class="loading-text">Loading {{downloadProgress}}%</div>
    <div class="loading-bars">
      <div class="loading-bar"></div>
      <div class="loading-bar"></div>
      <div class="loading-bar"></div>
    </div>
  </div>

  <div #rootRef
       style="width: 100%; height: 100%; display: none;"
       [style.display]="downloadProgress < 100 ? 'none' : 'inherit'">
  </div>

  <div class="gamePlayFooter">
    <app-carousel [games]="allGames" [hideMeta]="false" [showMaxGames]="true"></app-carousel>
  </div>

</div>
