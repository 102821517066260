import { Injectable, Inject, PLATFORM_ID, Renderer2, RendererFactory2} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class JsonLdService {
  private renderer: Renderer2;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
              private rendererFactory: RendererFactory2) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  // Method to insert JSON-LD into the head
  insertStructuredData(jsonLd: object) {
    if (isPlatformBrowser(this.platformId)) {
      const jsonLdStr = JSON.stringify(jsonLd);
      let scriptTag = document.getElementById('json-ld-script');

      if (!scriptTag) {
        // Create a new script tag if it doesn't exist
        scriptTag = this.renderer.createElement('script');
        this.renderer.setAttribute(scriptTag, 'type', 'application/ld+json');
        this.renderer.setAttribute(scriptTag, 'id', 'json-ld-script'); // Give it an ID for future reference
        this.renderer.appendChild(document.head, scriptTag);
      }

      // Update the script tag's text content
      // console.log('adding', jsonLdStr);
      this.renderer.setProperty(scriptTag, 'text', jsonLdStr);
    }
  }

  // Method to remove any previous JSON-LD script tags
  removeStructuredData() {
    if (isPlatformBrowser(this.platformId)) {
      const scriptTag = document.getElementById('json-ld-script');
      if (scriptTag) {
        // console.log('remove script', scriptTag);
        this.renderer.removeChild(document.head, scriptTag);
      }
    }
  }
}
