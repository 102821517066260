import {Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { GamesService } from '../../../services/games.service';
import { GeneralService } from '../../../services/general.service';
import { dic } from '../../../dictionary';

import { HttpClient } from '@angular/common/http';
import { ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { DosPlayer as Instance, DosPlayerFactoryType } from 'js-dos';
import {HttpEventType} from "@angular/common/http";
import {Router} from "@angular/router";
import { CarouselComponent } from '../carousel/carousel.component';
import { IconsComponent } from '../icons/icons.component';
import { NgIf } from '@angular/common';
import { StarRatingModule } from 'angular-star-rating';
declare const Dos: DosPlayerFactoryType;
declare let FB: any;

@Component({
    selector: 'app-run-game',
    templateUrl: './run-game.component.html',
    styleUrls: ['./run-game.component.scss'],
    standalone: true,
    imports: [StarRatingModule, NgIf, IconsComponent, CarouselComponent]
})


export class RunGameComponent implements OnInit, OnDestroy  {
  @ViewChild('rootRef', { static: true }) rootRef: ElementRef<HTMLDivElement> | undefined;
  dos: Instance | null = null;

	dic = dic;
  gameRated: boolean = false;
  currentGameURL = `${dic.CONSTANTS.appUrL}game/`;
  whatsappShareLink: string = '';
  tweeterShareLink: string = '';
  instagramShareLink: string = 'https://www.instagram.com/dosgamesworld/';

  @Input() gameInfo: any;
  @Output() onExitGame: EventEmitter<any> = new EventEmitter<any>();
  allGames: any;
  downloadProgress: number = 0;

	constructor(private gamesService: GamesService, private generalService: GeneralService,
              private renderer: Renderer2, private http: HttpClient, private router: Router) {
	}


	ngOnInit() {
    this.allGames = this.gamesService.getStaticGames();

    this.gamesService.dosboxSetFlagSkipTutorial();

    this.loadJsDosScript(() => {
      if (this.gameInfo.noFile) {
        this.downloadGame(this.gameInfo, (err: any, fileUrl: any) => {
          this.playGame(this.gameInfo, fileUrl);
        });
      }
      else {
        this.playGame(this.gameInfo, '');
      }

      this.currentGameURL += this.gameInfo.fileName;

      // this.facebookLink(this.gameInfo);
      this.whatsappLink(this.gameInfo);
      this.tweeterLink(this.gameInfo);
    });
  }

  loadJsDosScript(cb: any) {
    const scriptJsDos = this.renderer.createElement('script');
    scriptJsDos.type = 'text/javascript';
    scriptJsDos.src = 'assets/dos/js-dos.js'; // Replace with the actual script URL

    scriptJsDos.onload = () => {
      const scriptEmulatorsPath = this.renderer.createElement('script');
      scriptEmulatorsPath.type = 'text/javascript';
      scriptEmulatorsPath.text = 'emulators.pathPrefix = "assets/dos/";';

      setTimeout(() => {
        this.renderer.appendChild(document.body, scriptEmulatorsPath);
        cb();
      }, 200);
    };

    this.renderer.appendChild(document.body, scriptJsDos);
  }

  downloadGame(gameInfo: any, cb: any) {
     const url = `https://backendgames.herokuapp.com/games/download/${gameInfo.fileName}`;
    //const url = `http://localhost:5000/games/download/${gameInfo.fileName}`;

    this.http.get(url, { responseType: 'arraybuffer', reportProgress: true, observe: 'events' }).subscribe(
      event => {
        if (event.type === HttpEventType.DownloadProgress) {
          // @ts-ignore
          this.downloadProgress = Math.round((100 * event.loaded) / event.total);
        }
        else if (event.type === HttpEventType.Response) {
          // @ts-ignore
          const file = new File([event.body], `${gameInfo.fileName}.jsdos`, { type: event.type });
          const fileURL = URL.createObjectURL(file);
          cb(null, fileURL);
        }
      },
      error => {
        console.error(error);
      }
    );
  }

  reportBug() {
    this.router.navigate([`/${dic.STATES.contactUs}`]);
  }

  facebookLink(gameInfo: any) {
    try {
      if (this.generalService.isBrowser()) {
        FB.XFBML.parse();
      }
    }
    catch (ex) {
    }
  }

  whatsappLink(gameInfo: any) {
    const message = `Play ${gameInfo.name} online here - `;
    const encodedMessage = encodeURIComponent(message);
    const encodedLink = encodeURIComponent(this.currentGameURL);
    this.whatsappShareLink = "https://api.whatsapp.com/send?text=" + encodedMessage + "%20" + encodedLink;
  }

  tweeterLink(gameInfo: any) {
    const message = `Play ${gameInfo.name} online here - `;
    const encodedMessage = encodeURIComponent(message);
    const encodedLink = encodeURIComponent(this.currentGameURL);
    this.tweeterShareLink = `https://twitter.com/share?text=${encodedMessage}&url=${encodedLink}`;
  }

  ngOnDestroy() {
    if (this.dos) {
      this.dos.stop();
    }
  }

  playGame(gameInfo: any, fileUrl: any) {
    // @ts-ignore
    const root = this.rootRef.nativeElement;
    const instance = Dos(root); // Use the imported Dos object

    this.dos = instance;

    const bundleUrl = fileUrl || `assets/games/${this.gameInfo.fileName}.jsdos`;
    this.dos.run(bundleUrl);

    // this.gamesService.updateGameViewed(this.gameInfo);

    setTimeout(() => {
      this.downloadProgress = 100;
    }, 1300);
  }

	closeGame() {
    if (this.dos) {
      this.dos.stop();
    }
    this.onExitGame.emit();
  }

  updateGameRate($event: any) {
    if (this.gameRated) {
      return;
    }
    this.gameRated = true;
    this.gamesService.updateGameRate(this.gameInfo, $event.rating);
  }
}

