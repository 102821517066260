<div class="bodyContainer">
	<div id="homeContainer">

		<div id="popularGame" *ngIf="allGamesData?.length">
			<h1>Popular DOS Games Online</h1>
      <app-carousel [games]="allGamesData" [gamesShow]="4"></app-carousel>
		</div>

		<div class="dataBG" id="randomGameContainer">
			<div id="randomGameContent">
				<div>
					<h2>Random Game</h2>
					<p (click)="randomGameStatus(true);" class="text" id="randomGameText">Hate having to choose which DOS game to play? <br>Just click
						here
						<svg version="1.1" x="0px" y="0px" viewBox="0 0 492 492"
							style="enable-background:new 0 0 492 492;" xml:space="preserve" height="15pt" width="15pt"
							class="downArrowIcon">
							<path d="M442.668,268.536l-16.116-16.12c-5.06-5.068-11.824-7.872-19.024-7.872c-7.208,0-14.584,2.804-19.644,7.872
						   L283.688,355.992V26.924C283.688,12.084,272.856,0,258.02,0h-22.804c-14.832,0-28.404,12.084-28.404,26.924v330.24
						   L102.824,252.416c-5.068-5.068-11.444-7.872-18.652-7.872c-7.2,0-13.776,2.804-18.84,7.872l-16.028,16.12
						   c-10.488,10.492-10.444,27.56,0.044,38.052l177.576,177.556c5.056,5.056,11.84,7.856,19.1,7.856h0.076
						   c7.204,0,13.972-2.8,19.028-7.856l177.54-177.552C453.164,296.104,453.164,279.028,442.668,268.536z" />
						</svg>
					</p>
				</div>
				<div id="randomGame" class="gameImg" *ngIf="randomGame">
					<app-game-info [gameInfo]="randomGame" (mouseover)="randomGameStatus(false);"
						(mouseleave)="randomGameStatus(true);"></app-game-info>
				</div>
			</div>
		</div>

		<div class="dataBG" id="gameOfMonthContainer">
			<h2 class="subTitle">DOS Game of the Month</h2>

			<div>
				<div id="gameOfMonth" class="gameImg" *ngIf="gameOfMonth">
					<app-game-info [gameInfo]="gameOfMonth"></app-game-info>
				</div>
				<div id="gameOfMonthText" class="text">
					<p>
						Digger is a popular DOS game you can play online. It’s an arcade style game, for a single
						player. Your character is
						trapped in an intricate maze in the underground and you can dig horizontally or vertically
						through the ground, in order to escape from the ‘monsters’, which will appear in the maze and
						will try to destroy you. Your main purpose in the game is going to be collecting all the
						emeralds, which will give you 25 points for each emerald, and the gold bags. But you must be
						careful, since if the gold bags fall, they can squish both you and the monsters! You also have a
						weapon, with which you can kill all the monsters. Every time you kill one, you will earn 250
						points. Each level will be completed, as soon as you will kill all the monsters and collected
						all the emeralds.
					</p>
					<!--
					<p>{{gameOfMonth.metadata.desc}}</p>
          -->
				</div>
			</div>
		</div>

		<div class="dataBG" id="topPuzzleGameContainer">
			<h2 class="subTitle">Our Top Puzzle DOS Game</h2>

			<div>
				<div id="topPuzzleGame" class="gameImg" *ngIf="topPuzzleGame">
					<app-game-info [gameInfo]="topPuzzleGame"></app-game-info>
				</div>
				<div id="puzzleGameText" class="text">
					<p>
						The Incredible Machine (sometimes abbreviated as TIM) is a series of video games, in the genre
						of puzzles. The goal of the games is to create a series of Rube Goldberg devices: rearrange a
						collection of items in a needlessly complex way, in order to perform some simple tasks, like
						putting a ball into the box or starting a mixer and turning on a fan. There is a long list of
						countless available objects, like ropes and pulleys, generators, bowling balls, humans, most of
						which have specific functions and can interact differently with other objects. Every level can
						usually contain some fixed items that cannot be moved by the player, and so the only way to
						solve the puzzle is to arrange the objects around the still items and clear your way out. Start
						and play this DOS game online.
					</p>
				</div>
			</div>

		</div>

		<div class="dataBG" id="topActionGameContainer">
			<h2 class="subTitle">Our Top Action DOS Game</h2>

			<div>
				<div id="topActionGame" class="gameImg" *ngIf="topActionGame">
					<app-game-info [gameInfo]="topActionGame"></app-game-info>
				</div>
				<div id="actionGameText" class="text">
					<p>
						Commander Keen 4: Secret of the Oracle is a popular DOS game and one of a series of
						single-player side-scrolling
						platform video games. The series follows the eponymous Commander Keen, the secret identity of
						the eight-year-old genius Billy Blaze, as he defends the Earth and the galaxy from alien threats
						with his homemade spaceship, ray guns, and pogo stick. The majority of the game features the
						player-controlled Commander Keen viewed from the side while moving on a two-dimensional plane.
						In this episode, he receives a message from a mysterious land, full of magical and different
						locations, from castles in the deserts to caves and pyramids in an Aztek world type. There, he
						will meet many obstacles, unknown creatures and helpful friends that will help him in his
						journey.
					</p>
				</div>
			</div>
		</div>

		<div class="dataBG" id="newGamesContainer">
			<h2 class="subTitle">DOS Games Online</h2>
			<div id="newGames">
				<div *ngFor="let gameInfo of newGames" class="game">
					<a [routerLink]="['/'+dic.STATES.game+'/'+gameInfo.fileName]">
            <picture>
              <source srcset="../../assets/images/{{gameInfo.fileName}}.webp">
              <img src="../../assets/images/{{gameInfo.fileName}}.webp" width="320" height="200" style="object-fit: cover;" title="{{gameInfo.name}}"
                alt="{{gameInfo.name}}" class="newGameImg">
            </picture>
					</a>
				</div>
			</div>
		</div>
	</div>
</div>
