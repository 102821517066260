import { Component, OnInit } from '@angular/core';
import { GamesService } from '../../../services/games.service';
import { GeneralService } from '../../../services/general.service';
import { dic } from '../../../dictionary';
import { RouterLink } from '@angular/router';
import { GameInfoComponent } from '../../widgets/game-info/game-info.component';
import { CarouselComponent } from '../../widgets/carousel/carousel.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-home-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss'],
    standalone: true,
    imports: [NgIf, CarouselComponent, GameInfoComponent, NgFor, RouterLink]
})

export class HomePageComponent implements OnInit {

	dic = dic;
	allGamesData: any;
	gameOfMonth: any;
  topPuzzleGame: any;
  topActionGame: any;
	newGames: any;
	randomGame: any;
	randomGameInterval: any;

	constructor(private gamesService: GamesService, private generalService: GeneralService) { }

	ngOnInit() {
		this.generalService.updateState(dic.STATES.home, null);

    // first get data static data for performance
    this.getStaticGamesInfo();

    this.gamesService.getAllGames((err: any, games: any) => {

      games.sort((a: any, b: any) => b.counter - a.counter);

      this.allGamesData = games;
      this.getTopGames(this.allGamesData);
		});
	}


	getStaticGamesInfo() {
    this.allGamesData = this.gamesService.getStaticGames();
    this.allGamesData.sort((a: any, b: any) => b.counter - a.counter);

		this.getTopGames(this.allGamesData);
		this.getNewGames(this.allGamesData);
    this.setRandomGame();
	}

	randomGameStatus(enabled: boolean) {
    if (enabled && !this.randomGameInterval) {
      this.randomGameInterval = setInterval(() => {
        this.setRandomGame();
      }, 3000);
    }
    else {
      clearInterval(this.randomGameInterval);
      this.randomGameInterval = null;
    }
  }

	setRandomGame() {
		const gameIdx = Math.floor(Math.random() * this.allGamesData.length);
    this.randomGame = this.allGamesData[gameIdx];
	}

	getTopGames(allGamesData: any) {
		const GAME_OF_MONTH = 'Digger';
		this.gameOfMonth = allGamesData.find((gameData: any) => gameData.name === GAME_OF_MONTH);
		this.gameOfMonth.metadata = dic.GAMES_INFO[this.gameOfMonth.fileName as keyof typeof dic.GAMES_INFO];

    const TOP_PUZZLE_GAME = 'The Incredible Machine';
    this.topPuzzleGame = allGamesData.find((gameData: any) => gameData.name === TOP_PUZZLE_GAME);
    this.topPuzzleGame.metadata = dic.GAMES_INFO[this.gameOfMonth.fileName as keyof typeof dic.GAMES_INFO];

    const TOP_ACTION_GAME = 'Commander Keen 4';
    this.topActionGame = allGamesData.find((gameData: any) => gameData.name === TOP_ACTION_GAME);
    this.topActionGame.metadata = dic.GAMES_INFO[this.gameOfMonth.fileName as keyof typeof dic.GAMES_INFO];
	}

	getNewGames(staticGames: any) {

    const newGames = staticGames.slice();

    const randomItems = [];

    for (let i = 0; i < 6; i++) {
      // Generate a random index between 0 and newArray.length - 1
      const randomIndex = Math.floor(Math.random() * newGames.length);

      // Add the item at the random index to the randomItems array
      randomItems.push(newGames[randomIndex]);

      // Remove the chosen item from the newArray
      newGames.splice(randomIndex, 1);
    }

		this.newGames = randomItems;
	}
}
