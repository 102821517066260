<div class="bodyContainer">

  <div *ngIf="selectedBlog === 'top10'">
    <h1>Rediscovering the Top 10 DOS Games</h1>

    <div class="text3">
      <p>In the golden era of gaming, when high-end graphics and sophisticated consoles were yet to dominate the scene, there was a platform that captured the hearts of millions: DOS (Disk Operating System). DOS games delivered simple yet addictive gameplay, charming graphics, and memorable soundtracks. Join us on a nostalgic journey as we explore the top 10 DOS games that continue to hold a special place in the hearts of gamers worldwide.</p>
    </div>


    <div class="game text3">
      <h2 class="game-title"><a style="color: white" [routerLink]="['/'+dic.STATES.game+'/keen4']">Commander Keen 4</a></h2>
      <div class="game-description">
        <p>Meet Commander Keen, the brave kid who explored alien worlds and saved the galaxy from evil. This charming platformer showcased innovative level design, vibrant graphics, and catchy music. Commander Keen left an indelible mark on the gaming landscape and inspired many future game developers.</p>
        <div class="gamePicture">
          <picture>
            <source srcset="../../assets/images/keen4.webp">
            <img loading="lazy" src="../../assets/images/keen4.webp" width="320" height="200" [routerLink]="['/'+dic.STATES.game+'/keen4']"
                 itemprop="image" alt="keen4" style="cursor: pointer;">
          </picture>
          <div>
            <button itemprop="gameServer" [routerLink]="['/'+dic.STATES.game+'/keen4']" class="btn gameBtn playBtn">
              <app-icons [icon]="dic.ICONS.playGame"></app-icons>
              <span style="margin-left: 5px; font-size: 20px; font-weight: 600">Play Online</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="game text3">
      <h2 class="game-title"><a style="color: white" [routerLink]="['/'+dic.STATES.game+'/doom2']">Doom 2</a></h2>
      <div class="game-description">
        <p>Considered a groundbreaking first-person shooter, Doom pushed the boundaries of gaming in the 1990s. Armed with an arsenal of weapons, players battled hordes of demons in a dark and haunting environment. Its multiplayer mode revolutionized the gaming industry, setting the stage for future online multiplayer experiences.</p>
        <div class="gamePicture">
          <picture>
            <source srcset="../../assets/images/doom2.webp">
            <img loading="lazy" src="../../assets/images/doom2.webp" width="320" height="200" [routerLink]="['/'+dic.STATES.game+'/doom2']"
                 itemprop="image" alt="doom2" style="cursor: pointer;">
          </picture>
          <div>
            <button itemprop="gameServer" [routerLink]="['/'+dic.STATES.game+'/doom2']" class="btn gameBtn playBtn">
              <app-icons [icon]="dic.ICONS.playGame"></app-icons>
              <span style="margin-left: 5px; font-size: 20px; font-weight: 600">Play Online</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="game text3">
      <h2 class="game-title"><a style="color: white" [routerLink]="['/'+dic.STATES.game+'/pop']">Prince of Persia</a></h2>
      <div class="game-description">
        <p>Prince of Persia introduced us to the acrobatic hero battling through treacherous dungeons to save his beloved princess. This action-adventure game combined fluid animations, clever puzzles, and intense sword fights. Its atmospheric setting and engaging gameplay secured its place among the DOS classics.</p>
        <div class="gamePicture">
          <picture>
            <source srcset="../../assets/images/pop.webp">
            <img loading="lazy" src="../../assets/images/pop.webp" width="320" height="200" [routerLink]="['/'+dic.STATES.game+'/pop']"
                 itemprop="image" alt="pop" style="cursor: pointer;">
          </picture>
          <div>
            <button itemprop="gameServer" [routerLink]="['/'+dic.STATES.game+'/pop']" class="btn gameBtn playBtn">
              <app-icons [icon]="dic.ICONS.playGame"></app-icons>
              <span style="margin-left: 5px; font-size: 20px; font-weight: 600">Play Online</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="game text3">
      <h2 class="game-title"><a style="color: white" [routerLink]="['/'+dic.STATES.game+'/simcity2000']">SimCity 2000</a></h2>
      <div class="game-description">
        <p>Aspiring city planners rejoiced when SimCity arrived on the scene. This simulation game allowed players to design and manage their virtual cities, balancing budgets, zoning, and infrastructure. SimCity provided endless hours of strategic entertainment, empowering gamers to build and nurture their own metropolises.</p>
        <div class="gamePicture">
          <picture>
            <source srcset="../../assets/images/simcity2000.webp">
            <img loading="lazy" src="../../assets/images/simcity2000.webp" width="320" height="200" [routerLink]="['/'+dic.STATES.game+'/simcity2000']"
                 itemprop="image" alt="simcity2000" style="cursor: pointer;">
          </picture>
          <div>
            <button itemprop="gameServer" [routerLink]="['/'+dic.STATES.game+'/simcity2000']" class="btn gameBtn playBtn">
              <app-icons [icon]="dic.ICONS.playGame"></app-icons>
              <span style="margin-left: 5px; font-size: 20px; font-weight: 600">Play Online</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="game text3">
      <h2 class="game-title"><a style="color: white" [routerLink]="['/'+dic.STATES.game+'/wolfenstein3D']">Wolfenstein 3D</a></h2>
      <div class="game-description">
        <p>In Wolfenstein 3D, players infiltrated Nazi strongholds as the heroic B.J. Blazkowicz. Considered the pioneer of the first-person shooter genre, this game featured intense gunfights, labyrinthine levels, and memorable boss battles. Wolfenstein 3D laid the foundation for future iconic franchises like Doom and Quake.</p>
        <div class="gamePicture">
          <picture>
            <source srcset="../../assets/images/wolfenstein3D.webp">
            <img loading="lazy" src="../../assets/images/wolfenstein3D.webp" width="320" height="200" [routerLink]="['/'+dic.STATES.game+'/wolfenstein3D']"
                 itemprop="image" alt="wolfenstein3D" style="cursor: pointer;">
          </picture>
          <div>
            <button itemprop="gameServer" [routerLink]="['/'+dic.STATES.game+'/wolfenstein3D']" class="btn gameBtn playBtn">
              <app-icons [icon]="dic.ICONS.playGame"></app-icons>
              <span style="margin-left: 5px; font-size: 20px; font-weight: 600">Play Online</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="game text3">
      <h2 class="game-title"><a style="color: white" [routerLink]="['/'+dic.STATES.game+'/lemmings']">Lemmings</a></h2>
      <div class="game-description">
        <p>Enter the whimsical world of Lemmings, where tiny creatures relied on your guidance to navigate treacherous landscapes. This puzzle-platformer required quick thinking and precise timing to save the Lemmings from perilous traps. With its addictive gameplay and adorable characters, Lemmings captured the hearts of gamers worldwide.</p>
        <div class="gamePicture">
          <picture>
            <source srcset="../../assets/images/lemmings.webp">
            <img loading="lazy" src="../../assets/images/lemmings.webp" width="320" height="200" [routerLink]="['/'+dic.STATES.game+'/lemmings']"
                 itemprop="image" alt="lemmings" style="cursor: pointer;">
          </picture>
          <div>
            <button itemprop="gameServer" [routerLink]="['/'+dic.STATES.game+'/lemmings']" class="btn gameBtn playBtn">
              <app-icons [icon]="dic.ICONS.playGame"></app-icons>
              <span style="margin-left: 5px; font-size: 20px; font-weight: 600">Play Online</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="game text3">
      <h2 class="game-title"><a style="color: white" [routerLink]="['/'+dic.STATES.game+'/duke3d']">Duke Nukem 3D</a></h2>
      <div class="game-description">
        <p>Duke Nukem 3D brought a new level of interactivity and humor to the first-person shooter genre. As the cigar-chomping, wise-cracking Duke, players fought alien invaders and explored interactive environments. With its iconic one-liners, fast-paced action, and innovative gameplay elements, Duke Nukem 3D remains a fan favorite.</p>
        <div class="gamePicture">
          <picture>
            <source srcset="../../assets/images/duke3d.webp">
            <img loading="lazy" src="../../assets/images/duke3d.webp" width="320" height="200" [routerLink]="['/'+dic.STATES.game+'/duke3d']"
                 itemprop="image" alt="duke3d" style="cursor: pointer;">
          </picture>
          <div>
            <button itemprop="gameServer" [routerLink]="['/'+dic.STATES.game+'/duke3d']" class="btn gameBtn playBtn">
              <app-icons [icon]="dic.ICONS.playGame"></app-icons>
              <span style="margin-left: 5px; font-size: 20px; font-weight: 600">Play Online</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="game text3">
      <p class="game-description">DOS games shaped the gaming landscape and paved the way for future innovations in the industry. From educational simulations to thrilling shooters and mind-bending puzzles, these games offered endless hours of entertainment. Despite the advancement of technology, the charm and gameplay of these DOS classics continue to captivate gamers of all ages. So, dust off that vintage PC or explore these gems through modern platforms, and immerse yourself in the nostalgia of DOS gaming bliss.</p>
    </div>
  </div>

  <div *ngIf="selectedBlog === 'playOnline'">
    <h1>Relive the Retro Gaming Experience: Play Classic DOS Games Online</h1>


    <div class="text3">
      <p>In the era of modern gaming, it's easy to get caught up in the latest cutting-edge titles. However, sometimes, there's a nostalgic longing for the classic DOS games that defined our gaming experience in the past. The good news is that you can relive those cherished memories and enjoy your favorite retro titles right from your browser! In this blog post, we'll explore how to play classic DOS games online, and we'll introduce you to a fantastic platform that offers a vast collection of these nostalgic gems. Welcome to DOS Games World!</p>
    </div>

    <div class="game text3">
      <h2>Why Play Classic DOS Games?</h2>
      <p>Before diving into the specifics, let's take a moment to appreciate the allure of classic DOS games. These vintage titles hold a special place in the hearts of many gamers. They were the pioneers that laid the foundation for modern gaming, and they continue to be cherished for their unique gameplay mechanics, pixelated graphics, and memorable soundtracks. Playing classic DOS games not only provides a nostalgic trip down memory lane but also offers a chance to explore the origins of the gaming industry.</p>
    </div>

    <div class="game text3">
      <h2>Introducing DOS Games World:</h2>
      <div class="game-description">
        <p>When it comes to playing classic DOS games online, DOS Games World is an exceptional platform that deserves special mention. With its vast collection of titles spanning various genres and eras, this website allows you to rediscover your favorite games from the comfort of your web browser. The site features a user-friendly interface, making it easy to navigate and find the games you're looking for. The best part? It's entirely free and requires no downloads or installations.</p>
        <div class="gamePicture">
          <picture>
            <source srcset="../../assets/images/keen4.webp">
            <img loading="lazy" src="../../assets/images/keen4.webp" width="320" height="200" [routerLink]="['/'+dic.STATES.game+'/keen4']"
                 itemprop="image" alt="keen4" style="cursor: pointer;">
          </picture>
          <div>
            <button itemprop="gameServer" [routerLink]="['/'+dic.STATES.game+'/keen4']" class="btn gameBtn playBtn">
              <app-icons [icon]="dic.ICONS.playGame"></app-icons>
              <p>Play Keen 4 online</p>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="game text3">
      <h2>How to Play Classic DOS Games Online:</h2>
      <div class="game-description">
        <p>Now that you're acquainted with the allure of classic DOS games and DOS Games World, let's delve into the process of playing these retro gems online:</p>
        <div class="gamePicture">
          <picture>
            <source srcset="../../assets/images/doom2.webp">
            <img loading="lazy" src="../../assets/images/doom2.webp" width="320" height="200" [routerLink]="['/'+dic.STATES.game+'/doom2']"
                 itemprop="image" alt="doom2" style="cursor: pointer;">
          </picture>
          <div>
            <button itemprop="gameServer" [routerLink]="['/'+dic.STATES.game+'/doom2']" class="btn gameBtn playBtn">
              <app-icons [icon]="dic.ICONS.playGame"></app-icons>
              <p>Play Doom 2 online</p>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="game text3">
      <li>Visit DOS Games World:<br>
        Start by visiting the official website of DOS Games World at <a href="https://www.dosgamesworld.com/">https://www.dosgamesworld.com/</a>.</li>
      <li>Explore the Game Library:<br>
        Once on the website, you'll find an extensive collection of classic DOS games neatly organized into categories. Take your time to browse through the different genres or use the search bar to find a specific title.</li>
      <li>Select a Game:<br>
        When you find a game you want to play, click on its title to access its dedicated page. Here, you'll find a brief description, screenshots, and sometimes even user reviews.</li>
      <li>Play the Game:<br>
        To start playing, click on the "Play Now" or "Play Online" button on the game's page. The game will load in your browser, and you can use your keyboard or mouse to control the gameplay.</li>
      <li>Customize Your Experience:<br>
        DOS Games World often provides options to adjust sound, graphics, and other settings to optimize your gaming experience. Explore these options to personalize the game to your liking.</li>
    </div>


    <div class="game text3">
      <p>Playing classic DOS games online is a fantastic way to relive the golden age of gaming. With platforms like DOS Games World, you can easily access a vast collection of nostalgic titles and enjoy them right from your web browser. So, what are you waiting for? Visit DOS Games World today and embark on a retro gaming adventure that will transport you back in time.</p>
    </div>
  </div>

  <div *ngIf="selectedBlog === 'emulator'">
    <h1>Playing DOS Games in an Emulator</h1>

    <div class="text3">
      <div class="game-description">
        <p>Are you a fan of retro gaming? Do you miss the good old days of playing classic DOS games? Fortunately, with the help of emulators, you can now relive the nostalgia and enjoy your favorite DOS games on modern devices. In this blog post, we'll explore how to play DOS games in an emulator and highlight an excellent website, DOSGamesWorld.com, where you can find a vast collection of DOS games ready to be enjoyed.</p>
      </div>
    </div>

    <div class="text3">
      <h2>Understanding Emulators and DOS Games</h2>
      <div class="game-description">
        <p>Emulators are software programs that mimic the behavior of a specific hardware system, allowing you to run software or games designed for that system on different platforms. When it comes to DOS games, DOS emulators recreate the environment of the MS-DOS operating system, enabling you to play classic games without the need for an actual DOS computer.</p>
        <div class="gamePicture">
          <picture>
            <source srcset="../../assets/images/keen4.webp">
            <img loading="lazy" src="../../assets/images/keen4.webp" width="320" height="200" [routerLink]="['/'+dic.STATES.game+'/keen4']"
                 itemprop="image" alt="keen4" style="cursor: pointer;">
          </picture>
          <div>
            <button itemprop="gameServer" [routerLink]="['/'+dic.STATES.game+'/keen4']" class="btn gameBtn playBtn">
              <app-icons [icon]="dic.ICONS.playGame"></app-icons>
              <p>Play Keen 4</p>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="text3">
      <h2>Choosing an Emulator</h2>
      <div class="game-description">
        <p>To start playing DOS games on your modern device, you'll need to choose a suitable DOS emulator. Some popular options include DOSBox, Boxer, and PCem. These emulators offer various features such as customization options, joystick support, and compatibility with different operating systems.</p>
        <div class="gamePicture">
          <picture>
            <source srcset="../../assets/images/keen8.webp">
            <img loading="lazy" src="../../assets/images/keen8.webp" width="320" height="200" [routerLink]="['/'+dic.STATES.game+'/keen8']"
                 itemprop="image" alt="keen8" style="cursor: pointer;">
          </picture>
          <div>
            <button itemprop="gameServer" [routerLink]="['/'+dic.STATES.game+'/keen4']" class="btn gameBtn playBtn">
              <app-icons [icon]="dic.ICONS.playGame"></app-icons>
              <p>Play Keen 8</p>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="text3">
      <h2>Downloading and Setting Up the Emulator</h2>
      <div class="game-description">
        <p>Once you've selected an emulator, visit its official website to download the software. Follow the installation instructions provided, and ensure you download the version compatible with your operating system. After the installation, you may need to configure certain settings, such as specifying the location of the DOS games and adjusting audio and display options.</p>
        <div class="gamePicture">
          <picture>
            <source srcset="../../assets/images/keen5.webp">
            <img loading="lazy" src="../../assets/images/keen5.webp" width="320" height="200" [routerLink]="['/'+dic.STATES.game+'/keen5']"
                 itemprop="image" alt="keen5" style="cursor: pointer;">
          </picture>
          <div>
            <button itemprop="gameServer" [routerLink]="['/'+dic.STATES.game+'/keen5']" class="btn gameBtn playBtn">
              <app-icons [icon]="dic.ICONS.playGame"></app-icons>
              <p>Play Keen 5</p>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="text3">
      <h2>Discovering DOSGamesWorld.com</h2>
      <div class="game-description">
        <p>To find a wide selection of DOS games, head over to <a href="https://www.dosgamesworld.com/">DOSGamesWorld.com</a>. This website serves as a treasure trove for DOS gaming enthusiasts, offering an extensive collection of games across various genres. The site features a user-friendly interface, making it easy to search and browse through the available titles.</p>
        <div class="gamePicture">
          <picture>
            <source srcset="../../assets/images/keen10.webp">
            <img loading="lazy" src="../../assets/images/keen10.webp" width="320" height="200" [routerLink]="['/'+dic.STATES.game+'/keen10']"
                 itemprop="image" alt="keen10" style="cursor: pointer;">
          </picture>
          <div>
            <button itemprop="gameServer" [routerLink]="['/'+dic.STATES.game+'/keen5']" class="btn gameBtn playBtn">
              <app-icons [icon]="dic.ICONS.playGame"></app-icons>
              <p>Play Keen 10</p>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="text3">
      <h2>Exploring the DOSGamesWorld.com Experience</h2>
      <p>DOSGamesWorld.com provides a seamless experience for gamers. Here are some key features and benefits of the website:</p>
      <ul>
        <li>Vast Game Library: The website hosts an impressive range of DOS games, including popular titles like Commander Keen, Prince of Persia, and Duke Nukem.</li>
        <li>Simple Download Process: Each game page provides a brief description, screenshots, and a download link. You can quickly obtain the games and get started within minutes.</li>
        <li>User Ratings and Reviews: DOSGamesWorld.com allows users to rate and review games, helping you discover the most highly regarded titles and get insights from fellow gamers.</li>
        <li>Emulator Recommendations: The website offers guidance on choosing the right emulator and provides helpful tips to ensure optimal gaming performance.</li>
        <li>Community and Forums: Engage with a passionate community of DOS gamers, participate in discussions, and seek assistance or share your gaming experiences.</li>
      </ul>
    </div>

    <div class="text3">
      <h2>Playing DOS Games in the Emulator</h2>
      <div class="game-description">
        <p>Once you have downloaded your desired DOS game from DOSGamesWorld.com, launch the emulator and follow its instructions to mount the game files or directories. You can typically use simple commands within the emulator to navigate to the game's directory and run it. The emulator will simulate the DOS environment, allowing you to enjoy the game just as you would have in the past.</p>
        <div class="gamePicture">
          <picture>
            <source srcset="../../assets/images/keen1.webp">
            <img loading="lazy" src="../../assets/images/keen1.webp" width="320" height="200" [routerLink]="['/'+dic.STATES.game+'/keen1']"
                 itemprop="image" alt="keen1" style="cursor: pointer;">
          </picture>
          <div>
            <button itemprop="gameServer" [routerLink]="['/'+dic.STATES.game+'/keen1']" class="btn gameBtn playBtn">
              <app-icons [icon]="dic.ICONS.playGame"></app-icons>
              <p>Play Keen 1</p>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="text3">
      <h2>Conclusion</h2>
      <p>Thanks to emulators and websites like DOSGamesWorld.com, the joy of playing classic DOS games is accessible to everyone. Whether you want to revisit your favorite childhood games or explore new titles from the DOS era, the combination of a reliable emulator and a comprehensive game collection opens up a world of retro gaming opportunities. So, dust off those old gems and embark on a nostalgic journey through DOS gaming at DOSGamesWorld.com. Relive the magic, challenge your skills, and immerse yourself in the unforgettable world of DOS games.</p>
    </div>
  </div>

  <div *ngIf="selectedBlog === 'fun'">
    <h1>The Irresistible Charm of Playing DOS Games Online: Unveiling the Timeless Fun</h1>

    <div class="text3">
      <div class="game-description">
        <p>Explore the allure of playing DOS games online and discover why these retro gems continue to captivate gamers of all ages. Join us as we delve into the nostalgic appeal, unique gameplay, and the enduring fun that makes DOS games an absolute delight to play online.</p>
      </div>
    </div>

    <div class="text3">
      <div class="game-description">
        <p>In the vast realm of online gaming, where cutting-edge graphics and immersive experiences dominate, a hidden treasure trove of pure, unadulterated fun awaits those who venture into the world of DOS games. These vintage classics, born in the golden age of gaming, continue to enchant players with their timeless appeal. Join us as we embark on a journey to unravel the reasons why playing DOS games online is an experience like no other.</p>
      </div>
    </div>

    <div class="text3">
      <h2>Nostalgic Escapades:</h2>
      <div class="game-description">
        <p>For those who grew up with a joystick in hand and a CRT monitor before them, playing DOS games online is a nostalgic trip down memory lane. The familiar sights, sounds, and pixelated graphics evoke cherished memories, transporting us back to a simpler time. Reliving the games that sparked our passion for gaming fills us with a warm sense of nostalgia, rekindling the joy and wonder we experienced during our formative years.</p>
        <div class="gamePicture">
          <picture>
            <source srcset="../../assets/images/keen4.webp">
            <img loading="lazy" src="../../assets/images/keen4.webp" width="320" height="200" [routerLink]="['/'+dic.STATES.game+'/keen4']"
                 itemprop="image" alt="keen4" style="cursor: pointer;">
          </picture>
          <div>
            <button itemprop="gameServer" [routerLink]="['/'+dic.STATES.game+'/keen4']" class="btn gameBtn playBtn">
              <app-icons [icon]="dic.ICONS.playGame"></app-icons>
              <p>Play Keen 4</p>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="text3">
      <h2>Unique Gameplay:</h2>
      <div class="game-description">
        <p>DOS games possess a distinct charm that sets them apart from their modern counterparts. Unburdened by complex mechanics and realistic graphics, these games often feature straightforward controls and intuitive gameplay. Whether it's navigating treacherous platforming levels, solving intricate puzzles, or engaging in strategic battles, DOS games offer a refreshing simplicity that allows players to focus on pure, unadulterated fun.</p>
        <div class="gamePicture">
          <picture>
            <source srcset="../../assets/images/keen8.webp">
            <img loading="lazy" src="../../assets/images/keen8.webp" width="320" height="200" [routerLink]="['/'+dic.STATES.game+'/keen8']"
                 itemprop="image" alt="keen8" style="cursor: pointer;">
          </picture>
          <div>
            <button itemprop="gameServer" [routerLink]="['/'+dic.STATES.game+'/keen4']" class="btn gameBtn playBtn">
              <app-icons [icon]="dic.ICONS.playGame"></app-icons>
              <p>Play Keen 8</p>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="text3">
      <h2>Enduring Challenge:</h2>
      <div class="game-description">
        <p>Underneath their deceptively simple exteriors, DOS games often pack a surprising level of difficulty. The challenges they present require cunning strategies, quick reflexes, and puzzle-solving prowess. Unlike today's games with tutorials and hand-holding, DOS games throw players into the fray, demanding skill and adaptability. Conquering these challenges and overcoming the obstacles is a gratifying experience that keeps players coming back for more.</p>
        <div class="gamePicture">
          <picture>
            <source srcset="../../assets/images/keen5.webp">
            <img loading="lazy" src="../../assets/images/keen5.webp" width="320" height="200" [routerLink]="['/'+dic.STATES.game+'/keen5']"
                 itemprop="image" alt="keen5" style="cursor: pointer;">
          </picture>
          <div>
            <button itemprop="gameServer" [routerLink]="['/'+dic.STATES.game+'/keen5']" class="btn gameBtn playBtn">
              <app-icons [icon]="dic.ICONS.playGame"></app-icons>
              <p>Play Keen 5</p>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="text3">
      <h2>Timeless Stories:</h2>
      <div class="game-description">
        <p>DOS games captivate players with their memorable characters, captivating narratives, and immersive storytelling. Whether it's embarking on epic quests to save kingdoms, unraveling mysterious plotlines, or engaging in whimsical adventures, DOS games offer captivating narratives that stand the test of time. These narratives, often enhanced by imaginative artwork and unforgettable soundtracks, transport players to enchanting worlds where imagination knows no bounds.</p>
        <div class="gamePicture">
          <picture>
            <source srcset="../../assets/images/keen10.webp">
            <img loading="lazy" src="../../assets/images/keen10.webp" width="320" height="200" [routerLink]="['/'+dic.STATES.game+'/keen10']"
                 itemprop="image" alt="keen10" style="cursor: pointer;">
          </picture>
          <div>
            <button itemprop="gameServer" [routerLink]="['/'+dic.STATES.game+'/keen5']" class="btn gameBtn playBtn">
              <app-icons [icon]="dic.ICONS.playGame"></app-icons>
              <p>Play Keen 10</p>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="text3">
      <h2>Accessible and Versatile:</h2>
      <div class="game-description">
        <p>One of the beauties of playing DOS games online is the accessibility and versatility it offers. With no need for physical hardware or cumbersome installations, players can dive into these retro gems from any device with an internet connection. The ability to play anytime and anywhere provides a level of convenience that seamlessly integrates retro gaming into our modern, fast-paced lives.</p>
        <div class="gamePicture">
          <picture>
            <source srcset="../../assets/images/keen1.webp">
            <img loading="lazy" src="../../assets/images/keen1.webp" width="320" height="200" [routerLink]="['/'+dic.STATES.game+'/keen1']"
                 itemprop="image" alt="keen1" style="cursor: pointer;">
          </picture>
          <div>
            <button itemprop="gameServer" [routerLink]="['/'+dic.STATES.game+'/keen1']" class="btn gameBtn playBtn">
              <app-icons [icon]="dic.ICONS.playGame"></app-icons>
              <p>Play Keen 1</p>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="text3">
      <h2>Conclusion</h2>
      <p>Playing DOS games online is an invitation to experience the magic and timelessness of vintage gaming. From the comforting embrace of nostalgia to the simple yet addictive gameplay, these retro gems continue to enthrall players around the globe. Their enduring appeal lies in their ability to capture the essence of fun, challenging us while igniting our imagination. So, dust off your virtual time machine, explore the vast collection of DOS games available online, and embark on a journey that will transport you back to an era where pixelated adventures ruled supreme. Prepare to be captivated by the boundless joy and endless fun that DOS games offer – it's a journey well worth taking.</p>
    </div>
  </div>


  <div *ngIf="selectedBlog === 'policeQuest'">
    <h1>Rediscover the Thrill of Police Quest Games: Play Online and Relive the DOS Era</h1>

    <div class="text3">
      <div class="game-description">
        <p>
          Police Quest, the iconic series that captivated gamers in the 1980s and 1990s, continues to hold a special place in the
          hearts of gaming enthusiasts. If you're a fan of thrilling police adventures, then you're in for a treat! In this blog
          post, we'll delve into the world of Police Quest games, exploring their enduring popularity and how you can play them
          online to relive the nostalgic DOS era. So, fasten your seatbelts and prepare for a journey down memory lane!
        </p>
      </div>
    </div>

    <div class="text3">
      <h2>1. The Rise of Police Quest:</h2>
      <div class="game-description">
        <p>
          The Police Quest series, developed and published by Sierra On-Line, introduced players to the life of a police officer,
          offering a unique blend of realism and immersive storytelling. From traffic stops to crime investigations, these games
          challenged players with authentic police procedures and scenarios.
        </p>
<!--        <div class="gamePicture">
          <picture>
            <source srcset="../../assets/images/keen4.webp">
            <img loading="lazy" src="../../assets/images/keen4.webp" width="320" height="200" [routerLink]="['/'+dic.STATES.game+'/keen4']"
                 itemprop="image" alt="keen4" style="cursor: pointer;">
          </picture>
          <div>
            <button itemprop="gameServer" [routerLink]="['/'+dic.STATES.game+'/keen4']" class="btn gameBtn playBtn">
              <app-icons [icon]="dic.ICONS.playGame"></app-icons>
              <p>Play Keen 4</p>
            </button>
          </div>
        </div>-->
      </div>
    </div>

    <div class="text3">
      <h2>2. Police Quest: In Pursuit of the Death Angel</h2>
      <div class="game-description">
        <p>
          In Pursuit of the Death Angel, released in 1987, was the first game in the Police Quest series. Players took on the role
          of Sonny Bonds, a young police officer aiming to clean up the dangerous streets of Lytton. The game's realistic
          depiction of police work and its gripping storyline made it an instant hit among gamers.
        </p>
        <div class="gamePicture">
          <picture>
            <source srcset="../../assets/images/policeQuest1.webp">
            <img loading="lazy" src="../../assets/images/policeQuest1.webp" width="320" height="200" [routerLink]="['/'+dic.STATES.game+'/policeQuest1']"
                 itemprop="image" alt="policeQuest1" style="cursor: pointer;">
          </picture>
          <div>
            <button itemprop="gameServer" [routerLink]="['/'+dic.STATES.game+'/policeQuest1']" class="btn gameBtn playBtn">
              <app-icons [icon]="dic.ICONS.playGame"></app-icons>
              <p>Play Police Quest</p>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="text3">
      <h2>3. Police Quest II: The Vengeance</h2>
      <div class="game-description">
        <p>
          The Vengeance, released in 1988, continued the story of Sonny Bonds. This time, he set out to solve the murder of his
          police partner. Players navigated through a web of clues, interrogations, and dangerous encounters, making it a
          memorable addition to the series.
        </p>
        <div class="gamePicture">
          <picture>
            <source srcset="../../assets/images/policeQuest2.webp">
            <img loading="lazy" src="../../assets/images/policeQuest2.webp" width="320" height="200" [routerLink]="['/'+dic.STATES.game+'/policeQuest2']"
                 itemprop="image" alt="keen5" style="cursor: pointer;">
          </picture>
          <div>
            <button itemprop="gameServer" [routerLink]="['/'+dic.STATES.game+'/policeQuest2']" class="btn gameBtn playBtn">
              <app-icons [icon]="dic.ICONS.playGame"></app-icons>
              <p>Play Police Quest II</p>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="text3">
      <h2>4. Police Quest III: The Kindred</h2>
      <div class="game-description">
        <p>
          The Kindred, released in 1991, saw Sonny Bonds promoted to the Homicide Division. As players unraveled a complex murder
          mystery, they were exposed to the darker side of the criminal underworld. The game featured improved graphics and
          enhanced gameplay mechanics.
        </p>
        <div class="gamePicture">
          <picture>
            <source srcset="../../assets/images/policeQuest3.webp">
            <img loading="lazy" src="../../assets/images/policeQuest3.webp" width="320" height="200" [routerLink]="['/'+dic.STATES.game+'/policeQuest3']"
                 itemprop="image" alt="policeQuest3" style="cursor: pointer;">
          </picture>
          <div>
            <button itemprop="gameServer" [routerLink]="['/'+dic.STATES.game+'/policeQuest3']" class="btn gameBtn playBtn">
              <app-icons [icon]="dic.ICONS.playGame"></app-icons>
              <p>Play Police Quest III</p>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="text3">
      <h2>5. Police Quest IV: Open Season</h2>
      <div class="game-description">
        <p>
          Open Season, released in 1993, took a different approach by featuring a new protagonist, Detective John Carey. This
          installment explored the harrowing world of serial killers and showcased a more cinematic experience, complete with
          full-motion video sequences.
        </p>
        <div class="gamePicture">
          <picture>
            <source srcset="../../assets/images/policeQuest4.webp">
            <img loading="lazy" src="../../assets/images/policeQuest4.webp" width="320" height="200" [routerLink]="['/'+dic.STATES.game+'/policeQuest4']"
                 itemprop="image" alt="keen1" style="cursor: pointer;">
          </picture>
          <div>
            <button itemprop="gameServer" [routerLink]="['/'+dic.STATES.game+'/policeQuest4']" class="btn gameBtn playBtn">
              <app-icons [icon]="dic.ICONS.playGame"></app-icons>
              <p>Play Police Quest 4</p>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="text3">
      <h2>6. Police Quest: SWAT</h2>
      <div class="game-description">
        <p>
          SWAT, released in 1995, took the series in a different direction. Players joined the ranks of the Special Weapons and
          Tactics (SWAT) team, where they had to handle high-stakes hostage situations and tactical operations. This spin-off
          provided a unique perspective on law enforcement.
        </p>
<!--        <div class="gamePicture">
          <picture>
            <source srcset="../../assets/images/keen1.webp">
            <img loading="lazy" src="../../assets/images/keen1.webp" width="320" height="200" [routerLink]="['/'+dic.STATES.game+'/keen1']"
                 itemprop="image" alt="keen1" style="cursor: pointer;">
          </picture>
          <div>
            <button itemprop="gameServer" [routerLink]="['/'+dic.STATES.game+'/keen1']" class="btn gameBtn playBtn">
              <app-icons [icon]="dic.ICONS.playGame"></app-icons>
              <p>Play Keen 1</p>
            </button>
          </div>
        </div>-->
      </div>
    </div>

    <div class="text3">
      <h2>7. Police Quest: SWAT 2</h2>
      <div class="game-description">
        <p>
          SWAT 2, released in 1998, built upon the success of its predecessor. It offered improved graphics, more strategic
          gameplay, and a wide range of scenarios to tackle. The game allowed players to command their SWAT team and make
          critical decisions in real-time.
        </p>
<!--        <div class="gamePicture">
          <picture>
            <source srcset="../../assets/images/keen1.webp">
            <img loading="lazy" src="../../assets/images/keen1.webp" width="320" height="200" [routerLink]="['/'+dic.STATES.game+'/keen1']"
                 itemprop="image" alt="keen1" style="cursor: pointer;">
          </picture>
          <div>
            <button itemprop="gameServer" [routerLink]="['/'+dic.STATES.game+'/keen1']" class="btn gameBtn playBtn">
              <app-icons [icon]="dic.ICONS.playGame"></app-icons>
              <p>Play Keen 1</p>
            </button>
          </div>
        </div>-->
      </div>
    </div>

    <div class="text3">
      <h2>8. Police Quest: SWAT 3</h2>
      <div class="game-description">
        <p>
          SWAT 3, released in 1999, brought the series into the realm of 3D graphics and expanded the tactical gameplay elements.
          Players had to manage their team, employ negotiation techniques, and engage in intense gunfights as they dealt with
          various criminal situations.
        </p>
<!--        <div class="gamePicture">
          <picture>
            <source srcset="../../assets/images/keen1.webp">
            <img loading="lazy" src="../../assets/images/keen1.webp" width="320" height="200" [routerLink]="['/'+dic.STATES.game+'/keen1']"
                 itemprop="image" alt="keen1" style="cursor: pointer;">
          </picture>
          <div>
            <button itemprop="gameServer" [routerLink]="['/'+dic.STATES.game+'/keen1']" class="btn gameBtn playBtn">
              <app-icons [icon]="dic.ICONS.playGame"></app-icons>
              <p>Play Keen 1</p>
            </button>
          </div>
        </div>-->
      </div>
    </div>

    <div class="text3">
      <h2>9. Police Quest: SWAT 4</h2>
      <div class="game-description">
        <p>
          SWAT 4, released in 2005, marked a return to the original Police Quest series. Players assumed the role of the SWAT team
          leader and faced complex scenarios involving hostage rescue, bomb disposal, and tactical takedowns. Realism and
          strategic decision-making were key to success.
        </p>
<!--        <div class="gamePicture">
          <picture>
            <source srcset="../../assets/images/keen1.webp">
            <img loading="lazy" src="../../assets/images/keen1.webp" width="320" height="200" [routerLink]="['/'+dic.STATES.game+'/keen1']"
                 itemprop="image" alt="keen1" style="cursor: pointer;">
          </picture>
          <div>
            <button itemprop="gameServer" [routerLink]="['/'+dic.STATES.game+'/keen1']" class="btn gameBtn playBtn">
              <app-icons [icon]="dic.ICONS.playGame"></app-icons>
              <p>Play Keen 1</p>
            </button>
          </div>
        </div>-->
      </div>
    </div>


    <div class="text3">
      <h2>Conclusion</h2>
      <p>
        The Police Quest series remains a timeless classic, offering gamers a unique and immersive police experience. With the
        ability to play these games online, you can relive the DOS era and appreciate the rich storytelling and challenging
        gameplay they provide. So, grab your virtual badge and get ready to dive into the thrilling world of Police Quest!
      </p>
    </div>
  </div>


  <div class="blogSelection" style="color: white">
    <span class="blogItem" (click)="selectBlog('top10')">Top 10</span> |
    <span class="blogItem" (click)="selectBlog('playOnline')">Play Online</span> |
    <span class="blogItem" (click)="selectBlog('fun')">Fun play</span> |
    <span class="blogItem" (click)="selectBlog('emulator')">Emulator</span> |
    <span class="blogItem" (click)="selectBlog('policeQuest')">Police Quest</span>
  </div>
</div>
