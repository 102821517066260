import {Component, ElementRef, OnInit, ViewChild, HostListener} from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { GeneralService } from '../../../services/general.service';
import { GamesService } from '../../../services/games.service';
import { dic } from '../../../dictionary';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { FormsModule } from '@angular/forms';
import { NgClass, NgOptimizedImage, NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    animations: [
        trigger('navbarTog', [
            state('navShown', style({
                height: '210px',
            })),
            state('navHidden', style({
                height: '0'
            })),
            transition('navHidden <=> navShown', animate('400ms ease-in')),
        ])
    ],
    standalone: true,
    imports: [RouterLink, NgClass, NgOptimizedImage, FormsModule, NgIf, NgFor]
})

export class HeaderComponent implements OnInit {
  @ViewChild('headerTop', { static: false }) headerTop: ElementRef | undefined;

	constructor(private router: Router, private generalService: GeneralService, private gamesService: GamesService) {}

	dic = dic;
	state: string = '';
	searchGameText: any;
  allGames: any;
	searchGames: any;
  searchArrowIndex = 0;
	navbar = true;
	animState = 'navHidden';

	ngOnInit(): void {

	  // subscribe to event where page changes in order to update menu button
    this.generalService.currentState.subscribe(newState => {
      this.state = newState.state;

      if (this.generalService.isBrowser()) {
        // auto scroll to top of page
        setTimeout(() => {
          // @ts-ignore
          const element = this.headerTop.nativeElement;
          if (element) {
            element.scrollIntoView({behavior: 'smooth'});
          }
        }, 0);
      }
		});

    // for search
    this.allGames = this.gamesService.getStaticGames();
  }

/*  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event) {
    event.preventDefault();
    // Handle the scroll event here
  }*/

/*  @HostListener('window:scroll', ['$event'], { passive: true })
  onScrollPassive(event: Event) {
    // Handle the scroll event here without calling preventDefault()
  }*/

  searchGameEnterExecute() {
    if (this.searchGames && this.searchGames.length) {
      const gameToSearch = this.searchGames[this.searchArrowIndex].fileName;
      // 2 routes to enforce route to be called
      this.clearSearch();
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate([`/${dic.STATES.game}`, gameToSearch]));
    }
  }

  searchGameClickExecute(gameInfo: any) {
      // 2 routes to enforce route to be called
      this.clearSearch();
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate([`/${dic.STATES.game}`, gameInfo.fileName]));
  }

	clearSearch() {
    this.searchGames = [];
    this.searchGameText = '';
    this.searchArrowIndex = 0;
  }

  displaySearchResults(searchTxt: string) {
    if (!searchTxt) {
      this.searchGames = [];
      this.searchArrowIndex = 0;
      return;
    }

    this.searchGames = this.allGames.filter((gameObj: any) => {
      if (gameObj.name.toLowerCase().indexOf(searchTxt.toLowerCase()) > -1) {
        const re = new RegExp(searchTxt, 'gi');
        gameObj.foundGame = gameObj.name.replace(re, '<mark>$&</mark>');
        return true;
      }
      else {
        gameObj.foundGame = gameObj.name;
        return false;
      }
    });
  }

  searchArrowClick(isUp: boolean) {
	  if (!this.searchGames?.length) {
	    return;
    }
	  if (isUp) {
      this.searchArrowIndex--;
      if (this.searchArrowIndex < 0) {
        this.searchArrowIndex = this.searchGames.length - 1;
      }
    }
	  else {
      this.searchArrowIndex++;
      if (this.searchArrowIndex >= this.searchGames.length) {
        this.searchArrowIndex = 0;
      }
    }
  }
}


