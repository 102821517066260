import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { JsonLdService } from './json-ld.service';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import { dic } from '../dictionary';

@Injectable()
export class GeneralService {

	public state: string = '';
	// observer for notifying which page is currently displaying
	private stateSource = new BehaviorSubject({state: '', data: ''});
	currentState = this.stateSource.asObservable();

  constructor(private toastrService: ToastrService,
              private titleService: Title,
              private metaTagService: Meta,
              private jsonLdService: JsonLdService,
              @Inject(DOCUMENT) private document: Document,
              @Inject(PLATFORM_ID) private platformId: Object) { }

  isBrowser() {
    return isPlatformBrowser(this.platformId);
  }


	sendNotification(message: string) {
		this.toastrService.success(message);
	}

	updateState(state: string, gameInfo: any) {
	  this.state = state;
		// notify all subscribes that page is changed
		this.stateSource.next({state: state, data: gameInfo?.fileName});

    this.updateMetaData(state, gameInfo);
	}

  updateFavouriteEvent() {
    this.stateSource.next({state: 'favMark', data: ''});
  }

	updateMetaData(state: string, gameInfo: any) {
	  const metaDataInfo = {
      title: `${dic.CONSTANTS.appTitle}`,
      description: dic.CONSTANTS.metaData.desc,
      keywords: dic.CONSTANTS.metaData.keywords,

      // Facebook
      url: `${dic.CONSTANTS.appUrL}${state}`,
      image: `${dic.CONSTANTS.appUrL}assets/images/`
    };

	  if (gameInfo) {
	    metaDataInfo.title = `${gameInfo.name || ''}: play DOS games online for free`;
      metaDataInfo.description = gameInfo.meta || dic.CONSTANTS.metaData.desc;
      if (gameInfo.fileName) {
        metaDataInfo.url += `/${gameInfo.fileName}`;
        metaDataInfo.image += `${gameInfo.fileName}.webp`;
      }
      if (metaDataInfo.keywords) {
        metaDataInfo.keywords = `${gameInfo.name},${metaDataInfo.keywords}`;
      }
    }
	  else {
      metaDataInfo.title = `DOS Games World: play DOS games online for free`;

      metaDataInfo.image += `logo.png`;
    }

    this.titleService.setTitle(metaDataInfo.title);

    this.metaTagService.updateTag({ name: 'description', content: metaDataInfo.description });
    this.metaTagService.updateTag({ name: 'keywords', content: metaDataInfo.keywords });

    // Facebook
    this.metaTagService.updateTag({ property: 'og:title', content: metaDataInfo.title });
    this.metaTagService.updateTag({ property: 'og:url', content: metaDataInfo.url });
    this.metaTagService.updateTag({ property: 'og:description', content: metaDataInfo.description });
    this.metaTagService.updateTag({ property: 'og:image', content: metaDataInfo.image });

    // Twitter
    this.metaTagService.updateTag({ property: 'twitter:title', content: metaDataInfo.title });
    this.metaTagService.updateTag({ property: 'twitter:url', content: metaDataInfo.url });
    this.metaTagService.updateTag({ property: 'twitter:description', content: metaDataInfo.description });
    this.metaTagService.updateTag({ property: 'twitter:image', content: metaDataInfo.image });

    // @ts-ignore
    let link: HTMLLinkElement = this.document.querySelector("link[rel='canonical']");

    if (link) {
      link.href = metaDataInfo.url;
    }
    else {
      link = this.document.createElement('link');
      link.setAttribute('rel', 'canonical');
      link.setAttribute('href', metaDataInfo.url);
      this.document.head.appendChild(link);
    }

    // this.updateJsonLd(metaDataInfo, gameInfo);
  }

  updateJsonLd(metaDataInfo: any, gameInfo: any) {
    if (!gameInfo) {
      return;
    }

    const jsonLdObj = {
      "@context": "https://schema.org",
      "@type": "VideoGame",
      "name": gameInfo.name,
      "url": metaDataInfo.url,
      "image": metaDataInfo.image,
      "description": metaDataInfo.description,
      "genre": gameInfo.genre,
      "datePublished": `${gameInfo.year}-01-01`,
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": Math.round(gameInfo.rating?.sum / (gameInfo.rating?.counter || 1)),
        "reviewCount": gameInfo.rating?.counter || 0
      },
      "interactionStatistic": {
        "@type": "InteractionCounter",
        "interactionType": "https://schema.org/PlayAction",
        "userInteractionCount": gameInfo.counter
      },
      "operatingSystem": "MS-DOS",
      "gamePlatform": "Web Browser",
      "applicationCategory": "Game",
      "playMode": "SinglePlayer",
      "offers": {
        "@type": "Offer",
        "price": "0",
        "priceCurrency": "USD",
        "eligibleRegion": {
          "@type": "Place",
          "name": "Worldwide"
        }
      }
    };

    this.jsonLdService.removeStructuredData();
    this.jsonLdService.insertStructuredData(jsonLdObj);
  }
}
