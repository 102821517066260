import {Injectable} from '@angular/core';
import {GeneralService} from './general.service';
import {RouteService} from './route.service';
import {dic} from '../dictionary';

const USER_COOKIE_NAME = 'user_settings';

type gamesObject = {
  games: string[];
}

@Injectable()
export class UserService {

	constructor(private generalService: GeneralService, private routeService: RouteService) { }

	getUserFavourites() {
      const userFavs = localStorage.getItem(USER_COOKIE_NAME);
      if (userFavs) {
        return JSON.parse(userFavs);
      }
      return null;
	}

	updateGameFavourite(gameInfo: any, enabled: any) {
		if (enabled) {
			this.addGameToFavourites(gameInfo);
		}
		else {
			this.removeGameFromFavourites(gameInfo);
		}
    this.generalService.updateFavouriteEvent();
	}

	addGameToFavourites(gameInfo: any) {
		if (!gameInfo) {
			return;
		}
		gameInfo.userFavourite = true;

    if (this.generalService.isBrowser()) {
      const userFavs = this.getUserFavourites();

      let data: gamesObject = {games: []};
      if (userFavs) {
        data = userFavs;
      }

      const gameName = gameInfo.name as string;
      const gamesIdx = data.games.indexOf(gameName);
      if (gamesIdx !== -1) {
        console.warn(`game ${gameInfo.name} already in favourites`);
        return;
      }
      data.games.push(gameName);
      localStorage.setItem(USER_COOKIE_NAME, JSON.stringify(data));

      if (gameInfo._id) {
        gameInfo.favourite++;
        const updateData = {action: dic.GAME_ACTION.favourite, enabled: true};
        this.routeService.updateGame(gameInfo._id.toString(), updateData).subscribe((response: any) => {},
          (error: any) => {
            console.error(error);
          });
      }
    }

    this.generalService.sendNotification(`Added <b>${gameInfo.name}</b> to favourites`);
	}

	removeGameFromFavourites(gameInfo: any) {
		if (!gameInfo) {
			return;
		}
		gameInfo.userFavourite = false;

    if (this.generalService.isBrowser()) {
      const userFavs = this.getUserFavourites();
      if (!userFavs || !userFavs.games || !userFavs.games.length) {
        return;
      }

      const gamesIdx = userFavs.games.indexOf(gameInfo.name);
      if (gamesIdx !== -1) {
        userFavs.games.splice(gamesIdx, 1);
      }

      localStorage.setItem(USER_COOKIE_NAME, JSON.stringify(userFavs));

      if (gameInfo._id) {
        gameInfo.favourite--;
        const updateData = {action: dic.GAME_ACTION.favourite, enabled: false};
        this.routeService.updateGame(gameInfo._id.toString(), updateData).subscribe((response: any) => {},
          (error: any) => {
            console.error(error);
          });
      }
    }
    this.generalService.sendNotification(`Removed <b>${gameInfo.name}</b> from favourites`);
  }
}

