<div id="headerContainer" #headerTop>
    <div id="header">
        <div id="menu">
            <a id="title" [routerLink]="['/']">
              <span style="color: red;">DOS</span>
              <span style="color: mediumpurple; margin-left: 20px;">Games</span>
              <span style="color: yellow; margin-left: 20px;">World</span>:\>
            </a>

          <div class="navBar">
            <div style="display: flex; flex-direction: column; justify-content: center;">
              <div class="menuItems">
                <a [routerLink]="['/'+dic.STATES.games]" class="navItem"
                    [class.menuSelected]="state === dic.STATES.games">All Games</a> |
                <a [routerLink]="['/'+dic.STATES.favourites]" class="navItem" [ngClass]="{'blink': state === 'favMark'}"
                    [class.menuSelected]="state === dic.STATES.favourites">Favourite Games</a> |
                <a [routerLink]="['/'+dic.STATES.dosView]" class="navItem"
                     [class.menuSelected]="state === dic.STATES.dosView">DOS Terminal</a>
              </div>
              <div class="menuItems">
                <a [routerLink]="['/'+dic.STATES.action]" class="navItem"
                     [class.menuSelected]="state === dic.STATES.action">Action Games</a> |
                <a [routerLink]="['/'+dic.STATES.strategy]" class="navItem"
                     [class.menuSelected]="state === dic.STATES.strategy">Strategy Games</a> |
                <a [routerLink]="['/'+dic.STATES.sport]" class="navItem"
                     [class.menuSelected]="state === dic.STATES.sport">Sport Games</a> |
                <a [routerLink]="['/'+dic.STATES.arcade]" class="navItem"
                     [class.menuSelected]="state === dic.STATES.arcade">Arcade Games</a> |
                <a [routerLink]="['/'+dic.STATES.adventure]" class="navItem"
                     [class.menuSelected]="state === dic.STATES.adventure">Adventure Games</a>
              </div>
            </div>
          </div>
        </div>


        <div id="rightContainer">

          <div style=" display: flex; flex-direction: row; margin-bottom: 10px; gap: 5px; align-items: center;">
              <div style=" display: flex; flex-direction: row; align-items: center;" title="follow us on Instagram">
                <a href="https://www.instagram.com/dosgamesworld/" target="_blank">
                  <img ngSrc="../../assets/social/instagram.webp" width="48" height="48" alt="Instagram">
                </a>
              </div>
            <div style=" display: flex; flex-direction: row; align-items: center;" title="follow us on Facebook">
              <a href="https://www.facebook.com/profile.php?id=61568640056232" target="_blank">
                <img ngSrc="../../assets/social/facebook.webp" width="48" height="48" alt="Facebook">
              </a>
            </div>
              <div>
                <a class="donate-button" href=" https://www.paypal.com/donate/?hosted_button_id=TWKDYMPFVWFS6" rel="noopener" target="_blank">Donate</a>
              </div>
          </div>

            <div class="gameSearchContainer">
              <label class="searchLabel" for="search" style="display: block;"><b>Search:\></b></label>
                <span style="display: flex;">
                <input class="gamesSearchInput" (keydown.arrowUp)="searchArrowClick(true)" (keydown.arrowDown)="searchArrowClick(false)" placeholder="DOS game" id="search" [(ngModel)]="searchGameText" type="search" autocomplete="off"
                  maxlength="20" (keyup.enter)="searchGameEnterExecute()" (input)="displaySearchResults(searchGameText)">
              </span>

              <div *ngIf="searchGames?.length" class="gameSearchResultContainer">
                <div *ngFor="let gameInfo of searchGames; let i = index" [class.gameArrowSelected]="i === searchArrowIndex"
                     (keydown.arrowUp)="searchArrowClick(true)" (keydown.arrowDown)="searchArrowClick(false)">
                  <span (click)="searchGameClickExecute(gameInfo)" class="gameSearchResult" [innerHTML]="gameInfo.foundGame"></span>
                </div>
              </div>
            </div>
        </div>
    </div>
</div>

