<div class="bodyContainer">
  <div id="gameContainer">
    <h1 id="gameHeadline" itemprop="name">
      {{gameInfo.name}} | DOS game online
    </h1>

    <div id="descriptionContainer" itemprop="game" itemscope itemtype="https://schema.org/VideoGame">
      <meta itemprop="gamePlatform" content="DOS">
      <meta itemprop="applicationCategory" content="Game">

      <div id="descriptionMetadata">
        <div style="display: flex; gap: 5px;">
          <div>
            <a href="{{tweeterShareLink}}" target="_blank">
              <img src="../../assets/social/tweet.webp" width="48" height="48" alt="tweeter">
            </a>
          </div>

          <div>
            <a href="{{whatsappShareLink}}" target="_blank">
              <img src="../../assets/social/whatsapp.webp" width="48" height="48" alt="WhatsApp">
            </a>
          </div>

          <div>
            <a href="{{instagramShareLink}}" target="_blank">
              <img src="../../assets/social/instagram.webp" width="48" height="48" alt="Instagram">
            </a>
          </div>

          <div>
            <a href="{{tiktokShareUrl}}" target="_blank">
              <img src="../../assets/social/tiktok.webp" width="48" height="48" alt="TikTok">
            </a>
          </div>

          <!--          <div class="fb-share-button" [attr.data-href]="currentGameURL" data-layout="button"
                         data-size="large" id="fbBtn">
                    </div>-->
        </div>

        <div><span style="font-weight: bold;">Genre: </span>{{gameInfo.genre}} ({{gameInfo.year}})</div>

        <div>
          <span style="font-weight: bold;">Viewed: </span>{{gameInfo.counter || 0}}
        </div>

        <div style="display: flex; flex-direction: row; gap: 5px;">
          <div><span style="font-weight: bold;">Favourite: </span></div>
          <app-icons [gameInfo]="gameInfo" [icon]="dic.ICONS.favourite"></app-icons>
          <span *ngIf="gameInfo.favourite" style="font-size: 17px;">(By {{gameInfo.favourite}} players)</span>
        </div>

        <div style="display: flex; flex-direction: row; gap: 5px;">
          <div><span style="font-weight: bold;">Rating: </span></div>
          <star-rating
            style="cursor: pointer;"
            [hoverEnabled]="true"
            [starType]="'svg'"
            [rating]="(gameInfo.rating?.avg || 0)"
            (starClickChange)="updateGameRate($event)">
          </star-rating>
          <span style="font-size: 17px;">(By {{gameInfo.rating?.counter}} players)</span>
        </div>

        <div style="display: flex; flex-direction: row; gap: 10px;">
          <button (click)="downloadGame(gameInfo)" class="btn gameBtn" id="downloadBtn">
            <app-icons [icon]="dic.ICONS.downloadGame"></app-icons>
            <span style="margin-left: 5px; font-size: 18px;">Download Game</span>
          </button>
        </div>

      </div>

      <div id="descriptionPicturePlay">
        <picture>
          <source srcset="../../assets/images/{{gameInfo.fileName}}.webp">
          <img src="../../assets/images/{{gameInfo.fileName}}.webp" width="320" height="200" (click)="playGame(gameInfo)"
               itemprop="image" alt="{{gameInfo.name}}">
        </picture>

        <div style="width:100%">
          <button itemprop="gameServer" (click)="playGame(gameInfo)" class="btn gameBtn playBtn">
            <app-icons [icon]="dic.ICONS.playGame"></app-icons>
            <span style="margin-left: 5px; font-size: 20px; font-weight: 600">Play Online</span>
          </button>
        </div>
      </div>

    </div>

    <div id="playContainer" *ngIf="selectedGame">
      <app-run-game [gameInfo]="selectedGame" (onExitGame)="unselectGame()"></app-run-game>
    </div>

    <div id="descriptionText" class="text">
      <div>
        <div>
          <p itemprop="description" [innerHtml]="gameInfo.desc"></p>
          <p *ngIf="gameInfo.wiki"><b>Wikipedia: </b><a style="color: greenyellow;" rel="noopener" target="_blank" href="{{gameInfo.wiki}}">{{gameInfo.wiki}}</a> </p>
        </div>

        <div *ngIf="youtubeLink">
          <iframe
            width="560"
            height="315"
            [src]="youtubeLink"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen>
          </iframe>
        </div>

      </div>

      <div itemprop="description">
        <h3>{{gameInfo.name}} Cheat Codes</h3>
        <p *ngIf="gameInfo.cheats" [innerHtml]="gameInfo.cheats"></p>
        <p *ngIf="!gameInfo.cheats">Unfortunately, no cheat codes have been discovered for {{gameInfo.name}}.</p>
      </div>

      <div itemprop="description">
        <h3>{{gameInfo.name}} Keyboard Controls</h3>
        <span>This is how to play {{gameInfo.name}}:</span>
        <p *ngIf="gameInfo.keys" [innerHtml]="gameInfo.keys"></p>
        <p *ngIf="!gameInfo.keys">Unfortunately, no keyboard control have been discovered for {{gameInfo.name}}.</p>
      </div>

    </div>

  <!--  <div>
      <h2 class="subTitle">Comments</h2>
      <div id="facebookComments">
        <div class="fb-comments" [attr.data-href]="currentGameURL" data-numposts="5" data-width=""></div>
      </div>
    </div>-->

    <hr/>
    <div>
      <h2 class="subTitle" id="otherGamesHeadline">More online DOS games:</h2>
      <app-carousel [games]="gamesByGenre" [gamesShow]="4"></app-carousel>
    </div>
  </div>
</div>
