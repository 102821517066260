<div *ngIf="games && games.length"
     (keydown.arrowRight)="changeGames(true)"
     (keydown.arrowLeft)="changeGames(false)"
     class="carouselContainer"
     [class.animate-flicker]="isBtnGameClicked">
  <div>
    <button (click)="changeGames(false);" class="carouselButton"><</button>
  </div>

  <div *ngFor="let dummy of ' '.repeat(this.gamesShow).split(''), let x = index">
      <app-game-info
        [gameInfo]="games[currentGameIndex + x]"
        [hideMeta]="hideMeta"
        [autoStartGame]="true">
      </app-game-info>
  </div>

  <div>
    <button (click)="changeGames(true);" class="carouselButton">></button>
  </div>
</div>
