import {Injectable} from '@angular/core';
import {RouteService} from './route.service';
import {GeneralService} from './general.service';
import {UserService} from './user.service';
import {dic} from '../dictionary';



@Injectable()
export class GamesService {

	constructor(private routeService: RouteService, private userService: UserService,
              private generalService: GeneralService) {
  }

  sortGamesBy: string = 'played';
  allGames: any;
	staticGames: any;

	createStaticGames() {
	  if (this.staticGames && this.staticGames.length) {
	    return this.staticGames;
    }
	  this.staticGames = [];
    Object.keys(dic.GAMES_INFO).forEach((key) => {
      // @ts-ignore
      const gameInfo = dic.GAMES_INFO[key] as any;
      this.staticGames.push({name: gameInfo.name, fileName: gameInfo.fileName});
    });

    return this.staticGames;
  }

	getStaticGames() {
		// for fast loading
		return this.allGames || this.createStaticGames();
	}

	getAllGames(cb: any) {
		if (this.allGames) {
			return cb(null, this.allGames);
		}

		this.routeService.getAllGames().subscribe((response: any) => {
				this.allGames = response;
        this.allGames.forEach((gameObj: any) => {
          const gameInfo = (dic.GAMES_INFO)[gameObj.fileName as keyof typeof dic.GAMES_INFO];
          if (gameInfo) {
            Object.keys(gameInfo).forEach((key) => {
              // @ts-ignore
              gameObj[key] = gameInfo[key];
            });
          }

          if (gameObj.rating && gameObj.rating.counter) {
            gameObj.rating.avg = Math.floor(gameObj.rating.sum / gameObj.rating.counter);
          }
        });

        if (this.generalService.isBrowser()) {
          this.markFavouriteGames();
        }
				cb(null, this.allGames);
			},
      (err: any) => {
				console.error('error getting games', err);
				this.allGames = this.getStaticGames();
        if (this.generalService.isBrowser()) {
          this.markFavouriteGames();
        }
        cb(null, this.allGames);
			});
	}

	getGameInfo(gameName: string, cb: any) {
		this.getAllGames((err: any, games: any) => {
      const gamesInfo = games.find((gameData: any) => gameData.fileName === gameName);
			cb(null, gamesInfo);
		});
	}

	getGamesOfGenre(genre: string, cb: any) {
		this.getAllGames((err: any, games: any) => {
			cb(null, games.filter((gameData: any) => gameData.genre === genre));
		});
	}

  dosboxSetFlagSkipTutorial() {
    if (this.generalService.isBrowser()) {
      localStorage.setItem('emulators.ui.ui.tipsV2', "false");
      localStorage.setItem('emulators.ui.ui.autolockTipsV2', "false");
    }
  }

	markFavouriteGames() {
		const userFavourites = this.userService.getUserFavourites();
		if (userFavourites && userFavourites.games && userFavourites.games.length) {
			userFavourites.games.forEach((favGame: any) => {
				let foundGame = this.allGames.find((gameData: any) => gameData.name === favGame);
				if (foundGame) {
					foundGame.userFavourite = true;
				}
			});
		}
	}

	updateGameRate(gameInfo: any, newGameRate: any) {
		if (!newGameRate || gameInfo.rated) {
			return;
		}
		gameInfo.rated = true;

		if (!gameInfo.rating) {
			gameInfo.rating = {counter: 0, sum: 0};
		}
		gameInfo.rating.counter += 1;
		gameInfo.rating.sum += newGameRate;
		this.generalService.sendNotification(dic.MESSAGES.gameRated);

		const updateData = { action: dic.GAME_ACTION.rating, newRate: newGameRate };
		this.routeService.updateGame(gameInfo._id.toString(), updateData).subscribe((response: any) => {},
      (error: any) => {
			console.error(error);
		});
	}

	updateGameViewed(gameInfo: any) {
		if (!gameInfo.played) {
			gameInfo.played = true;
			gameInfo.counter += 1;

			const updateData = {action: dic.GAME_ACTION.counter};
			this.routeService.updateGame(gameInfo._id.toString(), updateData).subscribe((response: any) => {
			},
        (error: any) => {
				console.error(error);
			});
		}
	}
}
