<div *ngIf="gameInfo">
	<div class="gameContainer">
		<div id="gameTitle">
			<span (click)="selectGame()">{{gameInfo.name}}</span>
			<app-icons *ngIf="gameInfo.played" [icon]="dic.ICONS.played"></app-icons>
		</div>

    <picture *ngIf="generalService.state === dic.STATES.game">
      <source srcset="../../assets/images/{{gameInfo.fileName}}.webp">
      <img width="320" height="200"  (click)="selectGame()" loading="lazy"
			src="../../assets/images/{{gameInfo.fileName}}.webp" alt="{{gameInfo.name}}">
    </picture>

		<a *ngIf="generalService.state !== dic.STATES.game"
       [routerLink]="['/'+dic.STATES.game+'/'+gameInfo.fileName]"
       [state]="{ autoStartGame: autoStartGame}">
      <picture>
        <source srcset="../../assets/images/{{gameInfo.fileName}}.webp">
        <img loading="lazy" src="../../assets/images/{{gameInfo.fileName}}.webp" width="320" height="200" alt="{{gameInfo.name}}">
      </picture>
		</a>
	</div>

	<div *ngIf="!hideMeta" id="icons">
		<div class="iconContainer">
			<app-icons [icon]="dic.ICONS.rate"></app-icons>
			<div class="iconData">
				{{gameInfo.rating?.counter ? (gameInfo.rating.sum / gameInfo.rating.counter).toFixed(1) : 0}}
			</div>
		</div>

		<div class="iconContainer">
			<app-icons [gameInfo]="gameInfo" [icon]="dic.ICONS.favourite"></app-icons>
		</div>

		<div class="iconContainer">
			<app-icons [icon]="dic.ICONS.viewed"></app-icons>
			<div class="iconData">
				{{gameInfo.counter}}
			</div>
		</div>
	</div>
</div>
