import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule  } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { RecaptchaModule } from 'ng-recaptcha';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { StarRatingModule } from 'angular-star-rating';


import { AppRoutingModule } from './app-routing.module';

// components
import { AppComponent } from './app.component';
import { RunGameComponent } from './components/widgets/run-game/run-game.component';
import { HomePageComponent } from './components/pages/home-page/home-page.component';
import { GameComponent } from './components/pages/game/game.component';
import { BlogComponent } from './components/pages/blogs/blog.component';

import { HeaderComponent } from './components/widgets/header/header.component';
import { IconsComponent } from './components/widgets/icons/icons.component';
import { LoadingComponent } from './components/widgets/loading/loading.component';
import { GameInfoComponent } from './components/widgets/game-info/game-info.component';
import { FooterComponent } from './components/widgets/footer/footer.component';
import { CarouselComponent } from './components/widgets/carousel/carousel.component';

// lazy loader
import { AllGamesComponent } from './components/pages/all-games/all-games.component';
// import { ContactUsComponent } from './components/pages/contact-us/contact-us.component';
// import { DosScreenComponent } from './components/pages/dos-screen/dos-screen.component';
// import { PolicyComponent } from './components/pages/policy/policy.component';
// import { TermsComponent } from './components/pages/terms/terms.component';
// import { UserFavouritesComponent } from './components/pages/user-favourites/user-favourites.component';
// import { PageNotFoundComponent } from './components/pages/page-not-found/page-not-found.component';


// services
import { RouteService } from './services/route.service';
import { GamesService } from './services/games.service';
import { UserService } from './services/user.service';
import { GeneralService } from './services/general.service';

// pipes
import { FavouritePipe } from './pipes/favourite.pipe';
import {NgOptimizedImage} from "@angular/common";


@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        BrowserAnimationsModule,
        RecaptchaModule,
        StarRatingModule.forRoot(),
        ToastrModule.forRoot({ enableHtml: true }),
        NgOptimizedImage,
        HomePageComponent,
        RunGameComponent,
        AllGamesComponent,
        HeaderComponent,
        // ContactUsComponent,
        // UserFavouritesComponent,
        // DosScreenComponent,
        GameInfoComponent,
        // PageNotFoundComponent,
        GameComponent,
        FavouritePipe,
        IconsComponent,
        FooterComponent,
        LoadingComponent,
        // PolicyComponent,
        // TermsComponent,
        BlogComponent,
        CarouselComponent
    ],
    providers: [
        RouteService,
        UserService,
        GeneralService,
        GamesService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
