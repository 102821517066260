import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomePageComponent } from './components/pages/home-page/home-page.component';
import { GameComponent } from './components/pages/game/game.component';
import { BlogComponent } from './components/pages/blogs/blog.component';
import { AllGamesComponent } from './components/pages/all-games/all-games.component';

import {dic} from './dictionary';


const appRoutes: Routes = [
  { path: `${dic.STATES.game}/:name`, component: GameComponent, data: { title: { text: 'Play DOS games' }	} },

  { path: dic.STATES.games, component: AllGamesComponent, data: { title: { text: 'All DOS games' }} },
  { path: dic.STATES.action, component: AllGamesComponent, data: { title: { text: 'Action DOS games'}, genre: 'Action'}},
  { path: dic.STATES.strategy, component: AllGamesComponent, data: { title: { text: 'Strategy DOS games'}, genre: 'Strategy' }},
  { path: dic.STATES.arcade, component: AllGamesComponent, data: { title: { text: 'Arcade DOS games'}, genre: 'Arcade'}},
  { path: dic.STATES.sport, component: AllGamesComponent, data: { title: { text: 'Sport DOS games'}, genre: 'Sport' }},
  { path: dic.STATES.adventure, component: AllGamesComponent, data: { title: { text: 'Adventure DOS games'}, genre: 'Adventure' }},

  { path: dic.STATES.favourites, loadComponent: () => import('./components/pages/user-favourites/user-favourites.component').then(m => m.UserFavouritesComponent), data: { title: { text: 'Favourite DOS games' }	} },
  { path: dic.STATES.contactUs, loadComponent: () => import('./components/pages/contact-us/contact-us.component').then(m => m.ContactUsComponent), data: { title: { text: 'Contact us' }	} },
  { path: dic.STATES.dosView, loadComponent: () => import('./components/pages/dos-screen/dos-screen.component').then(m => m.DosScreenComponent), data: { title: { text: 'DOS Terminal' }} },
  { path: dic.STATES.policy, loadComponent: () => import('./components/pages/policy/policy.component').then(m => m.PolicyComponent), data: { title: { text: 'Policy' }} },
  { path: dic.STATES.terms, loadComponent: () => import('./components/pages/terms/terms.component').then(m => m.TermsComponent), data: { title: { text: 'Terms and Conditions' }} },

  { path: `${dic.STATES.blogs}/:name`, component: BlogComponent, data: { title: { text: 'Blogs' }} },
  // { path: dic.STATES.home, component: HomePageComponent, data: { title: { text: 'Home' }} },
  { path: '', component: HomePageComponent, data: { title: { text: 'Home' }} },
  { path: '**', loadComponent: () => import('./components/pages/page-not-found/page-not-found.component').then(m => m.PageNotFoundComponent) }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
