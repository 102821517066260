import { Component, Input, OnInit } from '@angular/core';
import { GeneralService } from '../../../services/general.service';
import { Router, RouterLink } from '@angular/router';
import { dic } from '../../../dictionary';
import { IconsComponent } from '../icons/icons.component';
import { NgIf } from '@angular/common';


@Component({
    selector: 'app-game-info',
    templateUrl: './game-info.component.html',
    styleUrls: ['./game-info.component.scss'],
    standalone: true,
    imports: [NgIf, IconsComponent, RouterLink]
})
export class GameInfoComponent implements OnInit {

	dic = dic;
	@Input() gameInfo: any;
  @Input() hideMeta: boolean = false;
  @Input() autoStartGame: boolean = false;

  constructor(public generalService: GeneralService, private router: Router) {}

	ngOnInit(): void {}

	selectGame() {
		// 2 routes to enforce route to be called
		this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
			this.router.navigate([`/${dic.STATES.game}`, this.gameInfo.fileName], {state: {autoStartGame: this.autoStartGame}}));
	}
}
