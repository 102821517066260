import {Component, Input, OnInit} from '@angular/core';
import { UserService } from '../../../services/user.service';
import { dic } from '../../../dictionary';
import { NgSwitch, NgSwitchCase } from '@angular/common';

@Component({
    selector: 'app-icons',
    templateUrl: './icons.component.html',
    styleUrls: ['./icons.component.scss'],
    standalone: true,
    imports: [NgSwitch, NgSwitchCase]
})
export class IconsComponent implements OnInit {

	constructor(private userService: UserService) { }

	dic = dic;
	@Input() icon: string = '';
	@Input() gameInfo: any;

	ngOnInit(): void {}

	updateGameFavourite(gameInfo: any, enabled: boolean) {
		if (!gameInfo || gameInfo.fake) {
			return;
		}
		this.userService.updateGameFavourite(gameInfo, enabled);
	}

}
